define("ember-rs/locales/de/templates/support-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportContent = void 0;
  var supportContent = {
    "needHelp": "Benötigen Sie Hilfe für RightSignature&trade;?",
    "articlesBelow": "Nachfolgend finden Sie Supportartikel und Videos, die mögliche Fragen zu RightSignature&trade; beantworten. Wenn die Artikel und Videos Ihre Frage nicht beantworten, wenden Sie sich an unsere Supportmitarbeiter.",
    "supportArticles": "Supportartikel",
    "overview": "Übersicht über RightSignature&trade; für ShareFile",
    "activate": "Wie aktiviere ich RightSignature&trade; für das ShareFile-Feature in meinem ShareFile-Konto?",
    "send": "Wie sende ich ein Dokument in ShareFile mit RS für SF?",
    "sign": "Wie signiere ich ein Dokument in ShareFile mit RS für SF?",
    "branding": "Wie richte ich das Branding für mein Unternehmen ein?",
    "changeEmail": "Wie ändere ich die E-Mail-Adresse, die meinem RightSignature&trade;-Konto zugeordnet ist?",
    "addUser": "Wie füge ich meinem RightSignature&trade;-Konto Benutzer hinzu?",
    "signerOrder": "Wie ordne ich Unterzeichnern für dasselbe Dokument eine Reihenfolge zu?",
    "annotate": "Wie kann ich mit RightSignature&trade; in Dokumenten Anmerkungen für die Unterzeichner erstellen?",
    "mergeFields": "Wie verwende ich Seriendruckfelder, um vor dem Senden Text in ein Dokument einzugeben?",
    "passcode": "Wie füge ich meinem Dokument einen Passcode hinzu?",
    "importOverlay": "Wie verwende ich die Funktion 'Überlagerung importieren'?",
    "docLocation": "Wo werden Dokumente mit RightSignature&trade; für ShareFile gespeichert?",
    "share": "Wie geben ich Dokumente frei?",
    "multiple": "Wie sende ich mehrere Dokumente zur Signatur?",
    "oneOff": "Wie sende ich ein Dokument? (einmalig)",
    "template": "Wie erstelle ich eine wiederverwendbare Vorlage?",
    "editTemplate": "Wie bearbeite ich eine Vorlage?",
    "sendTemplate": "Wie sende ich ein Vorlagendokument?",
    "embed": "Wie bette ich ein Dokument zur Signatur in meine Website ein?",
    "generate": "Wie erstelle ich einen Freigabelink für meine Dokumentvorlagen?",
    "delete": "Wie lösche ich ein Dokument?",
    "tag": "Wie markiere ich ein Dokument?",
    "selfSign": "Wie signiere ich mein eigenes Dokument?",
    "reports": "Welche Berichte und Statistiken sind in RightSignature&trade; verfügbar?",
    "complete": "Ausfüllen eines erhaltenen Dokuments",
    "remind": "Wie sende ich eine Erinnerung für das Signieren eines Dokuments?",
    "inPerson": "Wie lasse ich ein Dokument persönlich unterschreiben?",
    "showLess": "Weniger anzeigen",
    "showMore": "Mehr anzeigen",
    "sendQuestion": "Need Help? Visit our Help Center",
    "submitRequest": "Connect with our Support Team",
    "call": "Rufen Sie uns an",
    "free": "Gebührenfrei"
  };
  _exports.supportContent = supportContent;
});