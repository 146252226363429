define("ember-rs/locales/de/templates/unauthorized/rs3-login-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rs3LoginRedirect = void 0;
  var rs3LoginRedirect = {
    "looking": "Suchen Sie RightSignature&trade; - ShareFile Edition?",
    "sharefileEdition": "Sie sind auf dieser Seite, weil Sie versucht haben, sich an eine ältere Version von RightSignature&trade; anzumelden. Unsere Daten zeigen, dass Sie ein RightSignature&trade; - ShareFile Edition-Konto haben. Melden Sie sich unten an.",
    "support": "Haben Sie Fragen? <a href='https://support.sharefile.com/s/'>Kontaktieren Sie den Support</a> oder rufen Sie uns unter folgender Nummer an: 1-800-921-4250"
  };
  _exports.rs3LoginRedirect = rs3LoginRedirect;
});