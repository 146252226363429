define("ember-rs/locales/en/templates/components/archived-migrated-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.archivedMigratedModal = void 0;
  var archivedMigratedModal = {
    "expiredBilling": "Expired Billing!",
    "updateBillingDetails": "Your payment details are not up to date. Click below to update the billing details to continue using our service.",
    "clickUpdate": "Update",
    "contactSupport": "<a href='https://support.sharefile.com/s/' class='rs-button is-link is-secondary'>Contact Support</a>",
    "logout": "Logout"
  };
  _exports.archivedMigratedModal = archivedMigratedModal;
});