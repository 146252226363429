define("ember-rs/locales/es/templates/accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.account = void 0;
  var account = {
    "settings": {
      "header": "Parámetros",
      "description": "Estos son los parámetros para este usuario y cuenta.",
      "personal-info-header": "Su información",
      "account-info": {
        "header": "Parámetros",
        "company-name-header": "Nombre de la empresa",
        "current-plan-header": "Plan actual",
        "account-name-header": "Nombre de cuenta"
      },
      "verified-emails": {
        "header": "Parámetros",
        "header-description": "Agregue cualquiera de sus direcciones de correo electrónico a las que se pueden enviar los documentos. Su panel mostrará todos los documentos enviados a cualquiera de esas direcciones de correo electrónico.",
        "add-email": "Agregar correo electrónico verificado",
        "send-email": "Enviar correo electrónico de verificación",
        "enter-email": "Introduzca una dirección de correo electrónico..."
      },
      "account-settings": {
        "header": "Parámetros",
        "enable-passcode-header": "Requerir código de acceso para documentos",
        "enable-passcode-description": "Se requerirá un código de acceso para firmar el documento. Los remitentes pueden generar un código de acceso durante el proceso de envío para firma.",
        "enable-token-expiration-header": "Período predeterminado de caducidad del token",
        "enable-token-expiration-description": "Establezca la caducidad de todos los enlaces de documentos enviados por correo electrónico, incluidos los enlaces de firma y los enlaces de uso compartido.",
        "enable-blue-ink-header": "Firmas de tinta azul",
        "enable-blue-ink-description": "Todas las firmas capturadas en documentos enviados desde esta cuenta serán azules. Esto es para ayudar a distinguir un original firmado de una fotocopia.",
        "enable-integrations-description": "Permita que todos sus usuarios vean y establezcan conexiones con otras aplicaciones que utiliza. Al desactivarlo, ninguno de los usuarios podrá ver los parámetros Integraciones para habilitar/inhabilitar las integraciones listas para usar. Esto le ayudará a centralizar el control de acceso de todos sus usuarios a las integraciones disponibles.",
        "enable-signer-download-header": "Signer document download",
        "enable-signer-download-description": "Allow signers to download the original document before signing."
      },
      "mfa-settings": {
        "enable-mfa-header": "Verificación de dos pasos",
        "enable-mfa-description": "La verificación de dos pasos permite a los usuarios configurar un número de teléfono para recibir un código por SMS o voz. Los usuarios se inscriben a través del inicio de sesión.",
        "opt-out-mfa-description": "La verificación de dos pasos es obligatoria de forma predeterminada para todos los usuarios. Si no quiere llevarla a cabo, puede rellenar un documento de renuncia y, a continuación, cambiar la configuración.",
        "complete-mfa-waiver": "Completar documento de renuncia",
        "opt-out-waiver-title": "Documento de renuncia a la autenticación de dos factores",
        "opt-out-waiver-description": "Citrix recomienda encarecidamente la <a href='https://support.citrix.com/article/CTX277910'>verificación de dos pasos</a>, una forma de autenticación de varios factores (MFA), en el servicio RightSignature como una capa adicional de seguridad para reducir la probabilidad de acceso no autorizado a contenido de RightSignature.",
        "opt-out-waiver-consent": "Al rechazar la verificación de dos pasos para esta cuenta de RightSignature, usted certifica que tiene autoridad, en nombre de la entidad que adquirió la licencia de esta instancia de RightSignature, para asumir los riesgos y las responsabilidades que conlleva inhabilitar la verificación de dos pasos.",
        "opt-out-quit": "Salir",
        "opt-out-authority": "Sí, tengo la autoridad",
        "opt-out-title": "Elija los motivos por los que no quiere que la verificación de dos pasos proteja el contenido de esta cuenta de RightSignature:",
        "opt-out-reason-first": "No almacenamos datos confidenciales en nuestra cuenta de RightSignature",
        "opt-out-reason-second": "No tenemos la capacidad técnica necesaria para usar la verificación de dos pasos",
        "opt-out-description": "Al hacer clic en el botón Rechazar la verificación de dos pasos, certifico haber completado el formulario de buena fe y asumo todos los riesgos y responsabilidades asociados al hecho de no usar la verificación de dos pasos.",
        "opt-out-decline": "Rechazar la verificación de dos pasos",
        "opted-out": "{{optOutEmail}} rechazó el uso obligatorio el {{optOutDate}}. La verificación de dos pasos todavía se puede habilitar o inhabilitar desde el botón anterior.",
        "opted-out-no-context": "Se rechazó el uso obligatorio. La verificación de dos pasos todavía se puede habilitar o inhabilitar desde el botón anterior.",
        "opted-out-toast": "Esto confirma que ha rechazado implementar la verificación de dos pasos obligatoria para los usuarios de esta cuenta y que ha aceptado asumir los riesgos asociados en nombre de su organización."
      },
      "personal-settings": {
        "header": "Parámetros",
        "enable-field-detection-header": "Use la detección de campos para la preparación de documentos",
        "enable-field-detection-description": "Use la detección de campos automática al preparar documentos en lugar de colocar campos manualmente.",
        "enable-view-notification-header": "Notificar cuando un documento ha sido visto",
        "enable-view-notification-description": "Se le enviarán notificaciones cuando los firmantes hayan visto un documento que usted envió para su firma.",
        "enable-signature-notification-header": "Notificar cuando un documento ha sido firmado",
        "enable-signature-notification-description": "Se le enviarán notificaciones cada vez que un firmante haya firmado un documento del que usted es parte. (También recibirá una notificación al completarse el documento)",
        "enable-document-complete-notification-header": "Notificar cuando un documento ha sido completado",
        "enable-document-complete-notification-description": "Se le enviarán notificaciones cuando se haya ejecutado un documento del que usted forma parte.",
        "enable-contact-searching-header": "Búsqueda de contactos",
        "enable-contact-searching-description": "La búsqueda de contactos sugerirá contactos a medida que escriba un nombre al agregar firmantes, en función de los documentos enviados anteriormente, además de las listas de contactos integradas.",
        "default-expiration-setting-header": "Período de caducidad predeterminado",
        "default-expiration-setting-description": "Establezca la caducidad predeterminada del documento, en días. También puede modificar esto para documentos específicos, al enviar un documento nuevo.",
        "enable-send-reminder-email-header": "Enviar correos electrónicos de recordatorios",
        "enable-send-reminder-email-description": "Envíe un correo a los destinatarios de los documentos semanalmente (el tercer, el séptimo, el decimocuarto, el vigesimoprimero o el vigesimoctavo día) o diariamente (desde el séptimo día hasta el trigésimo día) si un documento sigue sin firmar.",
        "enable-attach-completed-pdf-header": "Adjuntar PDF completado en los correos electrónicos",
        "enable-attach-completed-pdf-description": "El PDF completado se adjuntará a la notificación que se envía a todas los participantes cuando se completa el documento. Los archivos PDF de más de 10 MB no se incluirán como datos adjuntos.",
        "default-signer-setting-header": "Hacer remitente como firmante de forma predeterminada",
        "default-signer-setting-description": "Hacer que de forma predeterminada el remitente tenga el rol de firmante en todos los documentos. También puede modificar esto para documentos específicos cuando envíe un documento nuevo.",
        "signature-types-settings-header": "Tipos de firma permitidos",
        "signature-types-settings-description": "Elija los tipos de firma que aceptará de los firmantes en sus documentos.",
        "signature-type-drawn": "Dibujada",
        "signature-type-typed": "Tecleada",
        "signature-type-uploaded": "Uploaded",
        "signature-type-uploaded-description": "Only available in the New Signer experience. This feature is currently unavailable for Signature Annotations and for workflows like Sign in Person, Template Share Links, and Web Embed links."
      },
      "saved-signature-settings": {
        "header": "Parámetros",
        "no-signature-description": "No hay firmas guardadas Una vez que firme un documento, sus firmas se almacenarán aquí para que usted las administre.",
        "signature-available-description": "La firma predeterminada se colocará cuando haga clic en un campo de firma. También puede modificar su firma para documentos específicos, al firmar un nuevo documento.",
        "confirm-deletion": "¿Seguro que quiere eliminar esta firma?",
        "default": "Predeterminado",
        "success": "La firma predeterminada se ha establecido con éxito.",
        "error": "Error al establecer la firma predeterminada."
      }
    },
    "integrations": {
      "header": "Parámetros",
      "description": "Estos son los parámetros para este usuario y cuenta."
    },
    "branding": {
      "header": "Parámetros",
      "disabled": {
        "description": "Estos son los parámetros para este usuario y cuenta."
      },
      "signing": {
        "description": "Estos son los parámetros para este usuario y cuenta.",
        "background-color-header": "Color de fondo:",
        "footer-color-header": "Color de pie de página:",
        "header-options-header": "Opciones de encabezado:",
        "header-show-logo": "Mostrar su logo",
        "header-no-logo": "Sin logo",
        "footer-options-header": "Opciones de pie de página:",
        "footer-option-send-name-only": "Enviar solo el nombre",
        "footer-option-company-name-only": "Solo el nombre de la empresa",
        "footer-option-company-name-only-tooltip": "Agregue un nombre de empresa arriba, en la pestaña 'Cuenta'.",
        "footer-document-name": "Nombre del documento",
        "footer-from": "De",
        "footer-at": "a",
        "footer-option-sender-and-company-name": "Remitente y nombre de la empresa",
        "footer-option-show-sender-avatar": "Mostrar avatar del remitente"
      },
      "email": {
        "header": "Parámetros",
        "description": "Estos son los parámetros para este usuario y cuenta.",
        "email-header": "Encabezado de correo electrónico:",
        "show-sender-from": "Mostrar nombre del remitente en el campo De",
        "show-company-subject": "Mostrar nombre de la empresa en el Asunto",
        "show-document-name-in-subject": "Usar documento en el Asunto",
        "custom-email-greeting-header": "Saludo personalizado:",
        "custom-email-greeting-description": "Esto se mostrará en la primera sección de todos los correos electrónicos para los firmantes.",
        "custom-email-signature-header": "Firma de correo electrónico personalizada:",
        "custom-email-signature-description": "Esto se mostrará en la última sección de todos los correos electrónicos para los firmantes.",
        "mergefield-help": "Puede usar los siguientes campos de combinación para agregar información personalizada a su correo electrónico:",
        "signer-name": "signer_name",
        "signer-first-name": "signer_first_name",
        "sender-name": "sender_name",
        "document-name": "document_name",
        "document-filename": "document_filename",
        "document-personalized-message": "document_personalized_message",
        "preview-from": "De:",
        "preview-subject": "Asunto:",
        "preview-no-logo": "Sin logo",
        "preview-greeting": "Hola",
        "preview-message": "le envió el documento \"{{filename}}\" para su firma",
        "preview-description": "Para revisar el documento y firmar con una firma electrónica, use este enlace:",
        "preview-action": "REVISAR DOCUMENTO"
      }
    },
    "users": {
      "trial-account-description": "Su plan actual no le permite administrar usuarios. Actualice el plan para acceder a esta función.",
      "admin-section-header": "Administrar usuarios",
      "admin-section-description": "Estos son los usuarios de esta cuenta de RightSignature&trade;. Como administrador de cuentas, puede agregar, quitar y promover a usuarios.",
      "non-admin-section-header": "Usuarios",
      "maximum-seats-alert-text": "Ha alcanzado la cantidad máxima de usuarios para su cuenta. Quite un usuario si desea agregar otro, o actualice su cuenta para agregar más licencias.",
      "seats-used": "Puestos usados: <b>{{seatsUsed}}</b> de <b>{{totalSeats}}</b>",
      "unknown-user": "(usuario desconocido)",
      "add-user-button-text": "Agregar usuario",
      "edit-user-button-text": "Modificar usuario",
      "span-text-me": "YO",
      "span-text-owner": "PROPIETARIO",
      "span-text-admin": "ADMINISTRACIÓN",
      "add": "Agregar",
      "account-users-new_introduction": "Introduzca el nombre y la dirección de correo electrónico de la persona que desea agregar a esta cuenta.",
      "first-name": "Nombre...",
      "last-name": "Apellido..."
    },
    "bills": {
      "header": "Parámetros",
      "contact": "Para ver o imprimir recibos de facturación de RightSignature, haga clic en el botón de descarga correspondiente que hay a continuación. <a href='https://support.sharefile.com/s/'>Llámenos</a> para obtener asistencia o <a href='https://support.sharefile.com/s/'>póngase en contacto con nosotros</a> si tiene más preguntas.",
      "date": "Fecha",
      "type": "Tipo",
      "status": "Estado"
    },
    "api": {
      "header": "Parámetros",
      "description": "Estos son los parámetros para este usuario y cuenta.",
      "primary-action": "Solicitar nueva clave"
    }
  };
  _exports.account = account;
});