define("ember-rs/locales/ja/templates/accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.account = void 0;
  var account = {
    "settings": {
      "header": "設定",
      "description": "このユーザーおよびアカウントの設定です。",
      "personal-info-header": "ユーザー情報",
      "account-info": {
        "header": "設定",
        "company-name-header": "会社名",
        "current-plan-header": "現在のプラン",
        "account-name-header": "アカウント名"
      },
      "verified-emails": {
        "header": "設定",
        "header-description": "ドキュメントが送信されるメールアドレスを追加します。ダッシュボードにこれらのメールアドレスに送信されたドキュメントが表示されます。",
        "add-email": "確認済みのメールを追加",
        "send-email": "確認メールを送信",
        "enter-email": "メール アドレスの入力..."
      },
      "account-settings": {
        "header": "設定",
        "enable-passcode-header": "ドキュメントのパスコードが必要",
        "enable-passcode-description": "ドキュメントに署名するためにはパスコードが必要です。送信者は、[署名用に送信] 処理中にパスコードを生成することができます。",
        "enable-token-expiration-header": "デフォルトのトークンの有効期限",
        "enable-token-expiration-description": "署名リンクと共有リンクを含むドキュメントへのすべてのメール リンクの有効期限を設定します。",
        "enable-blue-ink-header": "青インクの署名",
        "enable-blue-ink-description": "このアカウントから送信されたドキュメントでキャプチャされたすべての署名が青色で表示されるため、署名されたオリジナルとコピーを区別するのに役立ちます。",
        "enable-integrations-description": "この設定がオンになっていると、すべてのユーザーが、使用している他のアプリとの接続を表示および設定できます。オフに切り替えると、どのユーザーも、既定の統合を有効または無効にする設定を表示できなくなります。これは、すべてのユーザーの利用可能な統合へのアクセスを一元管理するのに役立ちます。",
        "enable-signer-download-header": "Signer document download",
        "enable-signer-download-description": "Allow signers to download the original document before signing."
      },
      "mfa-settings": {
        "enable-mfa-header": "2段階認証",
        "enable-mfa-description": "2 段階認証を有効にすると、ユーザーは電話番号を設定して SMS または音声通話で確認コードを受信できます。ユーザーはログイン プロセスで登録します。",
        "opt-out-mfa-description": "すべての従業員ユーザーにはデフォルトで 2 段階認証が必要です。この設定をオプトアウトする場合は、免責条項ページに記入してから設定を変更してください。",
        "complete-mfa-waiver": "オプトアウト免責条項ページに記入する",
        "opt-out-waiver-title": "2 段階認証のオプトアウト免責条項",
        "opt-out-waiver-description": "RightSignature で多要素認証 (MFA) の一種である <a href='https://support.citrix.com/article/CTX277910'>2 段階認証</a> を使用してセキュリティ レイヤーを追加し、RightSignature コンテンツへの未承認アクセスの危険性を抑えることを強くお勧めします。.",
        "opt-out-waiver-consent": "RightSignature アカウントの 2 段階認証の有効化を拒否することにより、この RightSignature インスタンスのライセンスを付与されたエンティティに代わって権限を持ち、2 段階認証を無効にすることのリスクと責任を負うことに同意することになります。",
        "opt-out-quit": "終了",
        "opt-out-authority": "権限を持つことを確認します",
        "opt-out-title": "この RightSignature アカウントのコンテンツを 2 段階認証で保護する必要がない理由を選択してください。",
        "opt-out-reason-first": "RightSignature アカウントに機密データを保存しない",
        "opt-out-reason-second": "2 段階認証を使用する技術に対応していない",
        "opt-out-description": "以下の [2 段階認証を拒否] ボタンをクリックすることで、フォームに誠意をもって記入したこと、および 2 段階認証を使用しないことによるすべてのリスクと責任を負うことに同意します。",
        "opt-out-decline": "2 段階認証を拒否",
        "opted-out": "2 段階認証の強制は、 {{optOutEmail}} によって {{optOutDate}} にオプトアウトされました。2 段階認証は、上のトグル ボタンをクリックして有効/無効にできます。",
        "opted-out-no-context": "2 段階認証の強制はオプトアウトされました。2 段階認証は、上のトグル ボタンをクリックして有効/無効にできます。",
        "opted-out-toast": "これによって、このアカウントのユーザーに 2 段階認証を強制的に展開することを拒否し、組織に代わって関連するリスクを負うことに同意することを承認します。"
      },
      "personal-settings": {
        "header": "設定",
        "enable-field-detection-header": "ドキュメントの準備にフィールド検出を使用",
        "enable-field-detection-description": "ドキュメントを準備するとき、手動でフィールドを配置するのではなくフィールドの自動検出を使用します。",
        "enable-view-notification-header": "ドキュメントが閲覧されたときに通知",
        "enable-view-notification-description": "署名用に送信したドキュメントを署名者が閲覧したときに、通知が送信されます。",
        "enable-signature-notification-header": "ドキュメントが署名されたときに通知",
        "enable-signature-notification-description": "ユーザーが関係するドキュメントに署名者が署名するたびに、ユーザーは通知を受信します (ドキュメントの完了時にも通知を受信します)。",
        "enable-document-complete-notification-header": "ドキュメントが完了したときに通知",
        "enable-document-complete-notification-description": "ユーザーが関係するドキュメントが署名されたときに、ユーザーは通知を受信します。",
        "enable-contact-searching-header": "連絡先の検索",
        "enable-contact-searching-description": "連絡先の検索では、署名者を追加するときに名前を入力すると、以前に送信されたドキュメントと統合された連絡先を基にして連絡先候補を表示します。",
        "default-expiration-setting-header": "デフォルトの有効期限",
        "default-expiration-setting-description": "ドキュメントの有効期限でデフォルトの日数を設定します。新しいドキュメントの送信時に、ドキュメント単位でこれを変更することもできます。",
        "enable-send-reminder-email-header": "通知メールの送信",
        "enable-send-reminder-email-description": "送信後にドキュメントが署名されていない場合、ドキュメントの受信者に毎週 (3、7、14、21、28 日) または毎日 (7 ～ 30 日) メールで知らせます。",
        "enable-attach-completed-pdf-header": "完了した PDF をメールに添付",
        "enable-attach-completed-pdf-description": "完了した PDF は、ドキュメントの全関係者に送信される完了通知に添付されます。10MB を超える PDF は添付されません。",
        "default-signer-setting-header": "デフォルトの送信者が署名者",
        "default-signer-setting-description": "送信者はデフォルトですべてのドキュメントの「署名者」の役割になります。新しいドキュメントの送信時に、ドキュメント単位でこれを変更することもできます。",
        "signature-types-settings-header": "許可される署名の種類",
        "signature-types-settings-description": "ドキュメントの署名者から承諾する署名の種類を選択してください。",
        "signature-type-drawn": "手書き入力",
        "signature-type-typed": "キー入力",
        "signature-type-uploaded": "Uploaded",
        "signature-type-uploaded-description": "Only available in the New Signer experience. This feature is currently unavailable for Signature Annotations and for workflows like Sign in Person, Template Share Links, and Web Embed links."
      },
      "saved-signature-settings": {
        "header": "設定",
        "no-signature-description": "保存済みの署名はありません。ドキュメントに署名後、署名はここに保存され管理できます。",
        "signature-available-description": "署名フィールドをクリックすると、デフォルトの署名が使用されます。新しいドキュメントの送信時に、ドキュメント単位でこれを変更することもできます。",
        "confirm-deletion": "この署名を削除してもよろしいですか?",
        "default": "デフォルト",
        "success": "デフォルトの署名を設定しました。",
        "error": "デフォルトの署名を設定できませんでした。"
      }
    },
    "integrations": {
      "header": "設定",
      "description": "このユーザーおよびアカウントの設定です。"
    },
    "branding": {
      "header": "設定",
      "disabled": {
        "description": "このユーザーおよびアカウントの設定です。"
      },
      "signing": {
        "description": "このユーザーおよびアカウントの設定です。",
        "background-color-header": "背景色：",
        "footer-color-header": "フッターの色:",
        "header-options-header": "ヘッダー オプション:",
        "header-show-logo": "ロゴの表示",
        "header-no-logo": "ロゴを使用しない",
        "footer-options-header": "フッター オプション:",
        "footer-option-send-name-only": "名前のみを送信",
        "footer-option-company-name-only": "会社名のみ",
        "footer-option-company-name-only-tooltip": "会社名を上の [アカウント] タブに追加する。",
        "footer-document-name": "ドキュメント名",
        "footer-from": "差出人",
        "footer-at": "時間: ",
        "footer-option-sender-and-company-name": "送信者および会社名(&C)",
        "footer-option-show-sender-avatar": "送信者のアバターを表示"
      },
      "email": {
        "header": "設定",
        "description": "このユーザーおよびアカウントの設定です。",
        "email-header": "メールのヘッダー:",
        "show-sender-from": "差出人に送信者名を表示",
        "show-company-subject": "件名に会社名を表示",
        "show-document-name-in-subject": "件名にドキュメントを使用",
        "custom-email-greeting-header": "メールの前文のカスタマイズ:",
        "custom-email-greeting-description": "すべての署名者メールの最初の段落に表示される内容です。",
        "custom-email-signature-header": "メールの署名のカスタマイズ:",
        "custom-email-signature-description": "すべての署名者メールの最後の段落に表示される内容です。",
        "mergefield-help": "以下のマージ フィールドを使用して、メールにカスタム情報を追加できます:",
        "signer-name": "signer_name",
        "signer-first-name": "signer_first_name",
        "sender-name": "sender_name",
        "document-name": "document_name",
        "document-filename": "document_filename",
        "document-personalized-message": "document_personalized_message",
        "preview-from": "差出人:",
        "preview-subject": "件名：",
        "preview-no-logo": "ロゴを使用しない",
        "preview-greeting": "お客様各位",
        "preview-message": "が、署名するドキュメント {{filename}} を送信しました",
        "preview-description": "ドキュメントを確認して電子署名を追加するには、このリンクをクリックしてください:",
        "preview-action": "ドキュメントの確認"
      }
    },
    "users": {
      "trial-account-description": "現在のプランでは、ユーザーを管理する権限がありません。この機能を利用するには、プランをアップグレードしてください。",
      "admin-section-header": "ユーザーの管理",
      "admin-section-description": "これらはこの RightSignature&trade; アカウントのユーザーです。アカウント管理者としてユーザーを追加、削除、昇格することができます。",
      "non-admin-section-header": "ユーザー",
      "maximum-seats-alert-text": "このアカウントで許可されるユーザーの最大数に達しました。ユーザーを追加するには既存のユーザーを削除するか、アカウントをアップグレードしてシートを追加してください。",
      "seats-used": "<b>{{seatsUsed}}</b> / <b>{{totalSeats}}</b> シート使用中",
      "unknown-user": "(不明なユーザー)",
      "add-user-button-text": "ユーザーの追加",
      "edit-user-button-text": "ユーザーの編集",
      "span-text-me": "自分",
      "span-text-owner": "所有者",
      "span-text-admin": "管理者",
      "add": "追加",
      "account-users-new_introduction": "このアカウントに追加するユーザーの名前とメールアドレスを入力してください。",
      "first-name": "名...",
      "last-name": "姓..."
    },
    "bills": {
      "header": "設定",
      "contact": "RightSignature の請求受領書を表示または印刷するには、以下の適切なダウンロード ボタンをクリックしてください。ご不明な点があれば、<a href='https://support.sharefile.com/s/'>電話</a>または<a href='https://support.sharefile.com/s/'>こちら</a>でサポートに連絡してください。",
      "date": "日付",
      "type": "種類",
      "status": "状態"
    },
    "api": {
      "header": "設定",
      "description": "このユーザーおよびアカウントの設定です。",
      "primary-action": "新しいキーを要求する"
    }
  };
  _exports.account = account;
});