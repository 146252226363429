define("ember-rs/locales/ja/templates/support-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportContent = void 0;
  var supportContent = {
    "needHelp": "RightSignature&trade; に関するヘルプ。",
    "articlesBelow": "以下は、RightSignature&trade; に関する一般的な質問に対応したサポート記事やビデオです。これらの記事やビデオで疑問が解決しない場合は、サポート担当者にお問い合わせください。",
    "supportArticles": "サポート記事",
    "overview": "RightSignature&trade; for ShareFile の概要",
    "activate": "ShareFile アカウントで RightSignature&trade; for ShareFile の機能を有効にする方法。",
    "send": "RS for SF を使用して ShareFile からドキュメントを送信する方法。",
    "sign": "RS for SF を使用して ShareFile でドキュメントに署名する方法。",
    "branding": "所属する組織のブランド設定をセットアップする方法。",
    "changeEmail": "使用している RightSignature&trade; アカウントに関連付けられたメールアドレスを変更する方法。",
    "addUser": "使用している RightSignature&trade; アカウントにユーザーを追加する方法。",
    "signerOrder": "同一ドキュメントに複数の署名者の順番を割り当てる方法。",
    "annotate": "RightSignature&trade; を使用して自分の署名者用にドキュメントに注釈を追加する方法。",
    "mergeFields": "マージ フィールドを使用して送信前にドキュメントにテキストを入力する方法。",
    "passcode": "自分のドキュメントにパスコードを追加する方法。",
    "importOverlay": "オーバレイのインポート機能を使用する方法。",
    "docLocation": "RightSignature&trade; for ShareFile でドキュメントを保存する場所。",
    "share": "ドキュメントの共有方法。",
    "multiple": "署名用に複数のドキュメントを送信する方法。",
    "oneOff": "1 個のドキュメントを送信する方法。 (1 回)",
    "template": "再利用可能なテンプレートを作成する方法。",
    "editTemplate": "テンプレートを編集する方法。",
    "sendTemplate": "テンプレート ドキュメントを送信する方法。",
    "embed": "署名用にドキュメントを Web サイトに埋め込む方法。",
    "generate": "テンプレート ドキュメント用に共有リンクを生成する方法。",
    "delete": "ドキュメントを削除する方法。",
    "tag": "ドキュメントのタグを設定する方法。",
    "selfSign": "自分のドキュメントに署名する方法。",
    "reports": "RightSignature&trade; で使用できるレポート機能と分析機能。",
    "complete": "受信したドキュメントを完了する方法",
    "remind": "ドキュメントへの署名を求める通知を送信する方法。",
    "inPerson": "ドキュメントに個人的に署名する方法。",
    "showLess": "簡易表示",
    "showMore": "詳細表示",
    "sendQuestion": "Need Help? Visit our Help Center",
    "submitRequest": "Connect with our Support Team",
    "call": "電話で連絡する",
    "free": "フリーダイヤル"
  };
  _exports.supportContent = supportContent;
});