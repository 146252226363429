define("ember-rs/locales/es/templates/unauthorized/no-integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.noIntegrations = void 0;
  var noIntegrations = {
    "noIntegrations": "Su plan ShareFile no es compatible con la integración de firma electrónica",
    "support": "¿Tiene alguna pregunta? <a href='https://support.sharefile.com/s/'>Contacte con la asistencia técnica</a> o llame al 1-800-921-4250"
  };
  _exports.noIntegrations = noIntegrations;
});