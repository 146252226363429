define("ember-rs/templates/components/main-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gUlqyq5L",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"account\",\"isWorkspaceEnabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"left\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/progress-logo-black.svg\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"currentUser\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/main-footer.hbs"
    }
  });

  _exports.default = _default;
});