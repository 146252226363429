define("ember-rs/locales/en/templates/accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.account = void 0;
  var account = {
    "settings": {
      "header": "Settings",
      "description": "These are the settings for this user and account.",
      "personal-info-header": "Your Information",
      "account-info": {
        "header": "Account Information",
        "company-name-header": "Company name",
        "current-plan-header": "Current plan",
        "account-name-header": "Account Name"
      },
      "verified-emails": {
        "header": "Verified Emails",
        "header-description": "Add any of your email addresses to which documents may be sent. Your dashboard will display all documents sent to any of these email addresses.",
        "add-email": "Add verified email",
        "send-email": "Send Verification Email",
        "enter-email": "Enter an email..."
      },
      "account-settings": {
        "header": "Account Settings",
        "enable-passcode-header": "Require passcode for documents",
        "enable-passcode-description": "Passcode will be required to sign the document. Senders can generate passcode during the send for signature process.",
        "enable-token-expiration-header": "Default token expiration period",
        "enable-token-expiration-description": "Set the expiration length for all emailed links to documents including signing links and share links.",
        "enable-blue-ink-header": "Blue ink signatures",
        "enable-blue-ink-description": "All signatures captured on documents sent from this account will be blue. This is to help distinguish a signed original from a photocopy.",
        "enable-integrations-description": "Allow all your users to view and set the connection with other apps you use. By toggling it off, none of the users will be able to view the Integrations settings to enable/ disable the out-of-box integrations. This is to help centrally control all your users' access to the available integrations.",
        "enable-signer-download-header": "Signer document download",
        "enable-signer-download-description": "Allow signers to download the original document before signing."
      },
      "mfa-settings": {
        "enable-mfa-header": "Two-Step Verification",
        "enable-mfa-description": "Two-Step verification allows users to setup a phone number to receive a code via SMS or Voice. Users enroll through the login process.",
        "opt-out-mfa-description": "Two-step verification is required by default for all users. If you would like to opt-out of this setting you can fill out a waiver, then change the setting.",
        "complete-mfa-waiver": "Complete Opt-Out Waiver",
        "opt-out-waiver-title": "Two-factor Authentication Opt-Out Waiver",
        "opt-out-waiver-description": "Citrix strongly recommends <a href='https://support.citrix.com/article/CTX277910'>Two-Step Verification</a> , a form of multi-factor authentication (MFA), on the RightSignature service as an additional layer of security to reduce the likelihood of unauthorized access to RightSignature content.",
        "opt-out-waiver-consent": "By declining to enable Two-Step verification for this RightSignature account, you certify that you have authority, on behalf of the entity that has licensed this instance of RightSignature, to assume the risks and liabilities of disabling Two-Step Verification.",
        "opt-out-quit": "Quit",
        "opt-out-authority": "I Have Authority",
        "opt-out-title": "Please choose the reason(s) that you do not want the content of this RightSignature Account protected by Two-Step Verification:",
        "opt-out-reason-first": "We do not store sensitive or confidential data in our RightSignature account",
        "opt-out-reason-second": "We do not have the technical capability to use two-step verification",
        "opt-out-description": "By clicking the Decline Two-Step Verification button below, I certify that I have completed the form in good faith and assume all risks and liabilities associated with not using Two-Step Verificaton.",
        "opt-out-decline": "Decline Two-Step Verification",
        "opted-out": "Mandatory enforcement was opted out on {{optOutDate}} by {{optOutEmail}}. Two-Step Verification can still be enabled/disabled by clicking the above toggle button.",
        "opted-out-no-context": "Mandatory enforcement was opted out. Two-Step Verification can still be enabled/disabled by clicking the above toggle button.",
        "opted-out-toast": "This confirms that you have declined to deploy two-step verification mandatorily for users under this account and that you have agreed to bear the associated risks on behalf of your organization."
      },
      "personal-settings": {
        "header": "Personal Settings",
        "enable-field-detection-header": "Use field detection for document preparation",
        "enable-field-detection-description": "Use Automatic field detection when preparing documents instead of manually placing fields.",
        "enable-view-notification-header": "Notify when a document has been viewed",
        "enable-view-notification-description": "Notifications will be sent to you when signers have viewed a document that you sent for signature.",
        "enable-signature-notification-header": "Notify when a document has been signed",
        "enable-signature-notification-description": "Notifications will be sent to you each time a signer has signed a document to which you are a party. (You will still receive notification upon document completion.)",
        "enable-document-complete-notification-header": "Notify when a document has been completed",
        "enable-document-complete-notification-description": "Notifications will be sent to you when a document that you are a party to has been executed.",
        "enable-contact-searching-header": "Contact searching",
        "enable-contact-searching-description": "Contact searching will suggest contacts as you type a name while adding signers based upon previously sent documents along with any enabled contact integrations.",
        "default-expiration-setting-header": "Default expiration period",
        "default-expiration-setting-description": "Set the default number of days for document expiration. You can also modify this on a per-document basis when sending a new document.",
        "enable-send-reminder-email-header": "Send reminder emails",
        "enable-send-reminder-email-description": "Email document recipients weekly (on 3rd, 7th, 14th, 21st, 28th day) or daily (starting on 7th day till 30th day) if a document remains unsigned.",
        "enable-attach-completed-pdf-header": "Attach completed PDF in emails",
        "enable-attach-completed-pdf-description": "The completed PDF will be attached to the complete notification that is sent to all parties of the document. PDFs that are larger than 10 MB will not be included as an attachment.",
        "default-signer-setting-header": "Default sender as signer",
        "default-signer-setting-description": "Default sender to a 'signer' role on all documents. You can also modify this on a per-document basis when sending a new document.",
        "signature-types-settings-header": "Signature types allowed",
        "signature-types-settings-description": "Choose the type(s) of signatures you will accept from signers on your documents.",
        "signature-type-drawn": "Drawn",
        "signature-type-typed": "Typed",
        "signature-type-uploaded": "Uploaded",
        "signature-type-uploaded-description": "Only available in the New Signer experience. This feature is currently unavailable for Signature Annotations and for workflows like Sign in Person, Template Share Links, and Web Embed links."
      },
      "saved-signature-settings": {
        "header": "Saved Signatures",
        "no-signature-description": "No saved signatures. Once you sign a document your signatures will be stored here for you to manage.",
        "signature-available-description": "The default signature will be placed when you click a signature field. You can also modify your signature on a per-document basis when signing a new document.",
        "confirm-deletion": "Are you sure you want to delete this signature?",
        "default": "Default",
        "success": "Successfully set the default signature.",
        "error": "Error setting the default signature."
      }
    },
    "integrations": {
      "header": "Integrations",
      "description": "Make the most out of RightSignature&trade; by connecting it to other apps you use. To enable an integration, toggle the switch to the right of that integration to the 'on' position and follow the authentication instructions."
    },
    "branding": {
      "header": "Customize Branding",
      "disabled": {
        "description": "Your current plan does not allow you to use custom branding. Upgrade your plan to access this feature."
      },
      "signing": {
        "description": "Make the signing experience for your recipients reflect your brand. Upload your logo and change colors.",
        "background-color-header": "Background Color:",
        "footer-color-header": "Footer Color:",
        "header-options-header": "Header Options:",
        "header-show-logo": "Show your logo",
        "header-no-logo": "No logo",
        "footer-options-header": "Footer Options:",
        "footer-option-send-name-only": "Send name only",
        "footer-option-company-name-only": "Company name only",
        "footer-option-company-name-only-tooltip": "Add a company name in the 'Account' tab above.",
        "footer-document-name": "Document Name",
        "footer-from": "From",
        "footer-at": "at",
        "footer-option-sender-and-company-name": "Sender &amp; company name",
        "footer-option-show-sender-avatar": "Show sender avatar"
      },
      "email": {
        "header": "Customize Signer Email",
        "description": "Customize the email to signers of your documents to reflect your company brand.",
        "email-header": "Email Header:",
        "show-sender-from": "Show sender name in from",
        "show-company-subject": "Show company name in subject",
        "show-document-name-in-subject": "Use document in subject",
        "custom-email-greeting-header": "Custom Email Greeting:",
        "custom-email-greeting-description": "This will show as the first section on all signer emails.",
        "custom-email-signature-header": "Custom Email Signature:",
        "custom-email-signature-description": "This will show as the last section on all signer emails.",
        "mergefield-help": "You can use the following merge fields to add custom information to your email:",
        "signer-name": "signer_name",
        "signer-first-name": "signer_first_name",
        "sender-name": "sender_name",
        "document-name": "document_name",
        "document-filename": "document_filename",
        "document-personalized-message": "document_personalized_message",
        "preview-from": "From:",
        "preview-subject": "Subject:",
        "preview-no-logo": "No logo",
        "preview-greeting": "Hello",
        "preview-message": "has sent you the document '{{filename}}' to sign",
        "preview-description": "To review the document and sign with an electronic signature, follow this link:",
        "preview-action": "REVIEW DOCUMENT"
      }
    },
    "users": {
      "trial-account-description": "Your current plan does not allow you to manage users. Upgrade your plan to access this feature.",
      "admin-section-header": "Manage Users",
      "admin-section-description": "These are the users on this RightSignature&trade; account. As an account admin you can add, remove, and promote users.",
      "non-admin-section-header": "Users",
      "maximum-seats-alert-text": "You have reached the maximum number of users for your account. Please remove a user if you would like to add another or upgrade your account to add more seats.",
      "seats-used": "<b>{{seatsUsed}}</b> of <b>{{totalSeats}}</b> seats used",
      "unknown-user": "(unknown user)",
      "add-user-button-text": "Add User",
      "edit-user-button-text": "Edit User",
      "span-text-me": "ME",
      "span-text-owner": "OWNER",
      "span-text-admin": "ADMIN",
      "add": "Add",
      "account-users-new_introduction": "Enter the name and email address of the person you would like to add to this account.",
      "first-name": "First Name...",
      "last-name": "Last Name..."
    },
    "bills": {
      "header": "Receipts &amp; Billing Notifications",
      "contact": "To view and/or print RightSignature billing receipts, click on the appropriate download button below. Please <a href='https://support.sharefile.com/s/'>call us</a> for assistance or <a href='https://support.sharefile.com/s/'>contact us</a> if you have additional questions.",
      "date": "Date",
      "type": "Type",
      "status": "Status"
    },
    "api": {
      "header": "API Keys",
      "description": "Manage your API Keys and request new credentials to integrate directly with the RightSignature&trade; API.",
      "primary-action": "Request New Key"
    }
  };
  _exports.account = account;
});