define("ember-rs/components/agree-to-terms", ["exports", "ember-rs/config/environment", "ember-rs/mixins/settings", "ember-rs/mixins/invalidate-and-sign-out"], function (_exports, _environment, _settings, _invalidateAndSignOut) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_settings.default, _invalidateAndSignOut.default, {
    ////////////////////////  DEPENDENCIES  ///////////////////
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    progressHomePage: _environment.default.progressHomePage,
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      disagreeToTerms: function disagreeToTerms() {
        return this.invalidateAndSignOut();
      },
      agreeToTerms: function agreeToTerms() {
        this.updateSetting('hasAgreedToTerms', 'has_agreed_to_terms', true, Ember.get(this, 'currentUser.model'), false);
        this.sendAction('agreeToTerms');
      }
    }
  });

  _exports.default = _default;
});