define("ember-rs/locales/it/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notFound = void 0;
  var notFound = {
    "doesNotExist": "Questa pagina non esiste.",
    "didYou": "È stato seguito un collegamento proveniente da un'altra parte di RightSignature&trade;?",
    "anotherPart": "Se questa pagina è stata raggiunta da un'altra parte di RightSignature&trade;, <a href='https://support.sharefile.com/s/'>contattare il supporto</a> in modo da correggere l'errore.",
    "followLink": "È stato seguito un collegamento da un altro sito?",
    "otherSite": "I collegamenti provenienti da altri siti possono talvolta essere obsoleti o contenere errori di ortografia. <a href='https://support.sharefile.com/s/'>Contattare il supporto</a> e comunicare su quale collegamento si è fatto clic per arrivare a questa pagina, in modo da poter contattare l'altro sito per risolvere il problema.",
    "typeUrl": "È stato digitato l'URL?",
    "doubleCheck": "L'indirizzo (URL) potrebbe non essere stato digitato correttamente. Verificare che l'ortografia, l'uso delle maiuscole/minuscole ecc. siano corretti.",
    "home": "Home"
  };
  _exports.notFound = notFound;
});