define("ember-rs/locales/fr/templates/support-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportContent = void 0;
  var supportContent = {
    "needHelp": "Besoin d'aide avec RightSignature&trade; ?",
    "articlesBelow": "Vous trouverez ci-dessous des articles de support et des vidéos qui répondent à bon nombre des questions que vous pourriez avoir concernant RightSignature&trade;. Si les articles et les vidéos ne répondent pas à votre question, n'hésitez pas à contacter nos représentants du support technique.",
    "supportArticles": "Articles de support",
    "overview": "Présentation de RightSignature&trade; pour ShareFile",
    "activate": "Comment activer RightSignature&trade; pour ShareFile dans mon compte ShareFile ?",
    "send": "Comment envoyer un document à partir de ShareFile en utilisant RS pour SF ?",
    "sign": "Comment signer un document à partir de ShareFile en utilisant RS pour SF ?",
    "branding": "Comment configurer la marque de mon entreprise ?",
    "changeEmail": "Comment modifier l'adresse e-mail associée à mon compte RightSignature&trade; ?",
    "addUser": "Comment ajouter des utilisateurs à mon compte RightSignature&trade; ?",
    "signerOrder": "Comment attribuer un ordre de signature sur le même document ?",
    "annotate": "Comment puis-je utiliser RightSignature&trade; pour annoter des documents pour les signataires ?",
    "mergeFields": "Comment utiliser les champs de fusion pour saisir du texte dans un document avant de l'envoyer ?",
    "passcode": "Comment ajouter un code d'accès à mon document ?",
    "importOverlay": "Comment utiliser la fonctionnalité Importer superposition ?",
    "docLocation": "Où RightSignature&trade; pour ShareFile stocke-t-il les documents ?",
    "share": "Comment partager des documents ?",
    "multiple": "Comment envoyer plusieurs documents pour signature ?",
    "oneOff": "Comment envoyer un document ? (une seule fois)",
    "template": "Comment créer un modèle réutilisable ?",
    "editTemplate": "Comment modifier un modèle ?",
    "sendTemplate": "Comment envoyer un document basé sur un modèle ?",
    "embed": "Comment incorporer un document dans mon site Web pour signature ?",
    "generate": "Comment générer un lien de partage pour mes documents basés sur des modèles ?",
    "delete": "Comment supprimer un document ?",
    "tag": "Comment baliser un document ?",
    "selfSign": "Comment puis-je signer mon propre document ?",
    "reports": "Quels sont les rapports et les statistiques disponibles avec RightSignature&trade; ?",
    "complete": "Comment remplir le document qui vous a été envoyé",
    "remind": "Comment envoyer un rappel pour signer un document ?",
    "inPerson": "Comment faire signer un document en personne ?",
    "showLess": "Afficher moins",
    "showMore": "Afficher plus",
    "sendQuestion": "Need Help? Visit our Help Center",
    "submitRequest": "Connect with our Support Team",
    "call": "Appelez-nous maintenant",
    "free": "Numéro gratuit"
  };
  _exports.supportContent = supportContent;
});