define("ember-rs/locales/de/templates/unauthorized/no-integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.noIntegrations = void 0;
  var noIntegrations = {
    "noIntegrations": "Ihr ShareFile-Abonnement unterstützt keine E-Signatur-Integration",
    "support": "Haben Sie Fragen? <a href='https://support.sharefile.com/s/'>Kontaktieren Sie den Support</a> oder rufen Sie uns unter folgender Nummer an: 1-800-921-4250"
  };
  _exports.noIntegrations = noIntegrations;
});