define("ember-rs/locales/nl/templates/components/hybris-trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisTrialModal = void 0;
  var hybrisTrialModal = {
    "ohNo": "Oh nee!",
    "reminder": "Ter herinnering:",
    "previewHasEnded": "Uw proefperiode met de nieuwste versie van RightSignature is afgelopen.",
    "freeTrialEnds": "Uw gratis proefperiode eindigt over {{trialDaysRemaining}} dag(en).",
    "needToGetThingsSigned": "Moeten er nog documenten ondertekend worden? RightSignature-bedrijfsabonnementen die worden verlengd zijn hun prijs meer dan waard.",
    "exploreAdminFeatures": "Verken de geavanceerde beheer- en beveiligingsfuncties",
    "retainDocuments": "Behoud de documenten en accountinstellingen van de oorspronkelijke versie",
    "easySetup": "Eenvoudige installatie, zonder contract – op elk moment te annuleren",
    "saveTimeAndPaper": "Bespaar een uur of meer aan administratief werk elke week",
    "choosePlan": "Kies een abonnement om door te gaan",
    "needAssitance": "Hulp nodig bij uw evaluatie?",
    "contactSupport": "<a href='https://support.sharefile.com/s/' class='rs-button is-link is-secondary'>Contact opnemen met Support</a>",
    "notSureYet": "Nog niet zeker? Kijk eens wat RightSignature voor uw bedrijf kan doen.",
    "logout": "Afmelden"
  };
  _exports.hybrisTrialModal = hybrisTrialModal;
});