define("ember-rs/locales/ja/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notFound = void 0;
  var notFound = {
    "doesNotExist": "このページは存在しません。",
    "didYou": "RightSignature&trade; 内のリンクから移動してきた場合。",
    "anotherPart": "RightSignature&trade; の他の部分からこのページに移動してきた場合は、<a href='https://support.sharefile.com/s/'>サポートに連絡</a>してください。担当者がリンクを修正します。",
    "followLink": "別のサイトのリンクから移動してきた場合。",
    "otherSite": "他のサイトのリンクが古くなっていたり、スペルが間違っていることがあります。<a href='https://support.sharefile.com/s/'>サポートに連絡</a>して、どのリンクからここに移動してきたか報告してください。担当者が該当サイトに連絡して、問題を解決できるよう試みます。",
    "typeUrl": "URL を手動で入力した場合。",
    "doubleCheck": "間違ったアドレス (URL) が入力された可能性があります。スペルや大文字小文字の使い分けなどに誤りがないか、確認してください。",
    "home": "ホーム"
  };
  _exports.notFound = notFound;
});