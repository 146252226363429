define("ember-rs/locales/zh-cn/templates/unauthorized/no-integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.noIntegrations = void 0;
  var noIntegrations = {
    "noIntegrations": "您的 ShareFile 计划不支持电子签名集成",
    "support": "有问题吗? 请<a href='https://support.sharefile.com/s/'>联系技术支持</a>或致电 1-800-921-4250"
  };
  _exports.noIntegrations = noIntegrations;
});