define("ember-rs/locales/en/templates/components/trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.trialModal = void 0;
  var trialModal = {
    "ohNo": "Oh no!",
    "reminder": "Just a reminder:",
    "trialExpired": "Your free trial has expired.",
    "trialEnds_singular": "Your free trial ends in {{trialDays}} day.",
    "trialEnds_plural": "Your free trial ends in {{trialDays}} days.",
    "day": "day",
    "upgradeMessage": "Still need to get things signed? RightSignature plans are priced to save your business time and money.",
    "accountSetup": "Easy setup, no contract -- cancel anytime.",
    "trialSetup": "Keep the documents and account setup from your trial.",
    "paperWork": "Save an hour or more of paperwork every week!",
    "moreTime": "Need more time with your evaluation?",
    "call": "<a href='https://support.sharefile.com/s/'>Call us</a>",
    "logout": "Logout",
    "notSure": "Not sure yet? See what RightSignature can do for your business."
  };
  _exports.trialModal = trialModal;
});