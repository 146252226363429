define("ember-rs/locales/zh-cn/templates/components/trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.trialModal = void 0;
  var trialModal = {
    "ohNo": "糟糕!",
    "reminder": "温馨提示:",
    "trialExpired": "您的免费试用已过期。",
    "trialEnds_singular": "您的免费试用将于 {{trialDays}} 天后结束。",
    "trialEnds_plural": "您的免费试用将于 {{trialDays}} 天后结束。",
    "day": "天",
    "upgradeMessage": "仍有需要签名的对象吗? 定价出售 RightSignature 计划是为了节省您的业务时间和金钱。",
    "accountSetup": "轻松设置，无合同 - 随时取消。",
    "trialSetup": "保留试用版中的文档和帐户设置。",
    "paperWork": "每周节省一小时或更长时间的文书工作时间!",
    "moreTime": "需要更多时间进行评估吗?",
    "call": "<a href='https://support.sharefile.com/s/'>给我们打电话</a>",
    "logout": "注销",
    "notSure": "还不确定? 了解 RightSignature 可以为您的业务做些什么。"
  };
  _exports.trialModal = trialModal;
});