define("ember-rs/locales/en/templates/onboarding/-agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "title": "Terms of Service",
    "body": "By using RightSignature&trade; you are certifying that you have read and agree to our {{partial 'terms-of-use-link'}} and <a href='{{progressHomePage}}/legal/privacy-center' target='_blank'>Privacy Policy</a>.",
    "noThanks": "No Thanks",
    "iAgree": "I Agree"
  };
  _exports.agreeToTerms = agreeToTerms;
});