define("ember-rs/locales/it/templates/components/review-and-send-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reviewAndSendItem = void 0;
  var reviewAndSendItem = {
    "passcode-message": "Ricordarsi che è necessario inviare questo PIN ai firmatari del documento.",
    "bulk-send-passcode-message": "È stato abilitato il passcode per le transazioni dei documenti. L'invio in blocco al momento non lo supporta.",
    "generate-new-pin": "Genera nuovo PIN",
    "pin-specification": "Il PIN deve essere un numero di cinque cifre.",
    "expiration-message": "Dopo la data di scadenza, il documento non sarà più disponibile per la firma.",
    "ExpiresIn": "Scade tra",
    "days": "giorni",
    "loading": "caricamento",
    "page": "pagina/e",
    "emailTip1": "Un collegamento per visualizzare questo documento verrà inviato a {{{email}}}.",
    "emailTip2": "Il destinatario sarà in grado di visualizzare e scaricare questo documento.",
    "enterEmail": "Immettere l'indirizzo e-mail",
    "shareDoc": "Immettere l'indirizzo e-mail per condividere questo documento",
    "ccMsg": "Aggiungi persone in CC per concedere loro l'accesso in sola lettura a questo documento. Riceveranno notifiche, saranno in grado di visualizzare lo stato di avanzamento e scaricare il documento completato. Se necessario, è possibile revocare l'accesso in un secondo momento.",
    "add": "aggiungi",
    "roles": "ruoli",
    "recipients": "destinatari",
    "cc": "cc: ",
    "ccCaps": "CC",
    "messageLimit": "Il messaggio non può contenere più di 100000 caratteri.",
    "addMsg": "Aggiungi un messaggio personalizzato...",
    "for": "per",
    "message": "messaggio",
    "pin": "pin",
    "tags": "contrassegni",
    "passcodeOn": "Passcode attivato",
    "passcodeOff": "Passcode disattivato",
    "restrictedFeatureMsg": "Il piano corrente non consente di aggiungere passcode ai documenti. Aggiornare il piano per accedere a questa funzionalità.",
    "kba": "KBA",
    "on": "Attivato",
    "off": "Disattivato",
    "kbaTip": "Richiedere ai firmatari di verificare la propria identità con un quiz basato sulla conoscenza. Ogni firmatario dovrà eseguire la verifica autonomamente prima di firmare il documento. I firmatari non potranno più accedere al documento dopo 3 tentativi non riusciti.",
    "enable": "Abilita",
    "addTag": "aggiungi contrassegno",
    "tagTip": "Aggiungere contrassegni a questo documento per trovarlo facilmente in un secondo momento. È anche possibile aggiungere valori ai contrassegni, ad esempio \"client_id: 1234\"",
    "addSalesforceOpportunity": "Aggiungi opportunità Salesforce",
    "addSalesforceOpportunityMsg": "Aggiungi un'opportunità Salesforce a questo documento",
    "exceededKbaLimit": "Limite di KBA raggiunto",
    "exceededKbaMsg": "È stato raggiunto il limite mensile dell'abbonamento corrente per i documenti abilitati per KBA. Per inviare più documenti con la funzione di autenticazione basata sulla conoscenza (KBA) abilitata, <a href='https://support.sharefile.com/s/'>contattare il supporto</a> per aggiornare l'account.",
    "tagName": "Nome contrassegno...",
    "tagValue": "Valore (facoltativo)",
    "sendTo": "Invia a",
    "name": "Nome",
    "docName": "Nome documento...",
    "totalNumberOfSigners": "Numero totale di firmatari:",
    "expiresIn": "La scadenza deve essere compresa tra 1 e 365 giorni."
  };
  _exports.reviewAndSendItem = reviewAndSendItem;
});