define("ember-rs/locales/en/templates/unauthorized/no-integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.noIntegrations = void 0;
  var noIntegrations = {
    "noIntegrations": "Your ShareFile plan does not support E-signature Integration",
    "support": "Have questions? <a href='https://support.sharefile.com/s/'>Contact Support</a> or call 1-800-921-4250"
  };
  _exports.noIntegrations = noIntegrations;
});