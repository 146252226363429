define("ember-rs/locales/it/templates/unauthorized/no-integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.noIntegrations = void 0;
  var noIntegrations = {
    "noIntegrations": "Il piano ShareFile in uso non supporta l'integrazione della firma elettronica",
    "support": "In caso di domande, <a href='https://support.sharefile.com/s/'>contattare il supporto</a> o chiamare il numero 1-800-921-4250"
  };
  _exports.noIntegrations = noIntegrations;
});