define("ember-rs/locales/de/templates/onboarding/-agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "title": "Nutzungsbedingungen",
    "body": "Durch die Verwendung von RightSignature&trade; bestätigen Sie, dass Sie unsere {{partial 'terms-of-use-link'}} und die <a href='{{progressHomePage}}/legal/privacy-center' target='_blank'>Datenschutzerklärung</a> gelesen haben und zustimmen.",
    "noThanks": "Nein, danke",
    "iAgree": "Zustimmen"
  };
  _exports.agreeToTerms = agreeToTerms;
});