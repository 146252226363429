define("ember-rs/locales/de/templates/components/review-and-send-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reviewAndSendItem = void 0;
  var reviewAndSendItem = {
    "passcode-message": "Vergessen Sie nicht, diese PIN an die Unterzeichner des Dokuments zu senden.",
    "bulk-send-passcode-message": "Sie haben den Passcode für Dokumenttransaktionen aktiviert. Dies wird derzeit für Massensendungen nicht unterstützt.",
    "generate-new-pin": "Neue PIN generieren",
    "pin-specification": "Die PIN muss eine fünfstellige Zahl sein.",
    "expiration-message": "Nach dem Ablaufdatum ist das Dokument nicht mehr zum Signieren verfügbar.",
    "ExpiresIn": "Ablauf in",
    "days": "Tagen",
    "loading": "Laden",
    "page": "Seite(n)",
    "emailTip1": "Ein Link zum Anzeigen dieses Dokuments wird an {{{email}}} gesendet.",
    "emailTip2": "Der Empfänger kann dieses Dokument anzeigen und herunterladen.",
    "enterEmail": "E-Mail-Adresse eingeben",
    "shareDoc": "Geben Sie eine E-Mail-Adresse ein, um dieses Dokument freizugeben",
    "ccMsg": "Fügen Sie Parteien unter CC hinzu, um ihnen Lesezugriff auf dieses Dokument zu gewähren. Die Parteien erhalten Benachrichtigungen, können den Fortschritt anzeigen und das abgeschlossene Dokument herunterladen. Sie können den Zugriff später bei Bedarf widerrufen.",
    "add": "Hinzu-fügen",
    "roles": "Rollen",
    "recipients": "Empfänger",
    "cc": "CC:  ",
    "ccCaps": "CC",
    "messageLimit": "Die Nachricht darf nicht mehr als 100.000 Zeichen enthalten.",
    "addMsg": "Fügen Sie eine persönliche Nachricht hinzu...",
    "for": "für",
    "message": "Nachricht",
    "pin": "PIN:",
    "tags": "Tags",
    "passcodeOn": "Passcode ein",
    "passcodeOff": "Passcode aus",
    "restrictedFeatureMsg": "Mit Ihrem aktuellen Abonnement können Sie Dokumenten keine Passcodes hinzufügen. Erweitern Sie Ihr Abonnement, um auf dieses Feature zuzugreifen.",
    "kba": "KBA",
    "on": "Ein",
    "off": "Aus",
    "kbaTip": "Unterzeichner müssen ihre Identität mit einem wissensbasierten Quiz verifizieren. Jeder Unterzeichner muss vor der Unterzeichnung des Dokuments eine unabhängige Verifizierung durchführen. Nach 3 fehlgeschlagenen Versuchen wird das Dokument für Unterzeichner gesperrt.",
    "enable": "Aktivieren",
    "addTag": "Tag hinzufügen",
    "tagTip": "Fügen Sie diesem Dokument Tags hinzu, um es später leichter zu finden. Sie können den Tags auch Werte hinzufügen, z. B. 'client_id: 1234'",
    "addSalesforceOpportunity": "Salesforce Opportunity hinzufügen",
    "addSalesforceOpportunityMsg": "Fügen Sie diesem Dokument eine Salesforce Opportunity hinzu",
    "exceededKbaLimit": "KBA-Limit erreicht",
    "exceededKbaMsg": "Sie haben das monatliche Limit Ihres aktuellen Abonnements für Dokumente mit wissensbasierter Authentifizierung (KBA) erreicht. Um weitere Dokumente mit der KBA-Funktion zu senden, <a href='https://support.sharefile.com/s/'>wenden Sie sich an den Support</a>, um ein Upgrade für Ihr Konto anzufordern.",
    "tagName": "Tagname...",
    "tagValue": "Wert (optional)",
    "sendTo": "Senden an",
    "name": "Name",
    "docName": "Name des Dokuments...",
    "totalNumberOfSigners": "Gesamtzahl der Unterzeichner:",
    "expiresIn": "Das Ablaufdatum muss zwischen 1 und 365 Tagen liegen."
  };
  _exports.reviewAndSendItem = reviewAndSendItem;
});