define("ember-rs/locales/nl/templates/onboarding/-entitlement-options-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.entitlementOptionsAdmin = void 0;
  var entitlementOptionsAdmin = {
    "title": "Welkom bij RightSignature!",
    "hasSeats": "U hebt momenteel beheerdersrechten voor dit account. Dat betekent dat u aan uzelf en/of aan andere gebruikers PRO-toegang en functies kunt verlenen. Klik op de onderstaande knop om deze toegang aan uzelf te verlenen.",
    "notNow": "Misschien later",
    "entitleMe": "PRO-toegang krijgen",
    "noSeats": "Alle seats in dit account zijn momenteel in gebruik! U kunt ofwel iemand verwijderen of meer seats aanvragen door <a href='https://support.sharefile.com/s/'>contact op te nemen met Support</a> of door te bellen naar <a href='tel:+18009214250'>1-800-921-4250</a>, of gewoon doorgaan zonder enkele functies.",
    "proceed": "Doorgaan"
  };
  _exports.entitlementOptionsAdmin = entitlementOptionsAdmin;
});