define("ember-rs/locales/ja/templates/unauthorized/rs3-login-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rs3LoginRedirect = void 0;
  var rs3LoginRedirect = {
    "looking": "RightSignature&trade; - ShareFile Edition をお探しですか?",
    "sharefileEdition": "RightSignature&trade; の古いバージョンにログインしようとしたため、このページが表示されました。レコードによると、RightSignature&trade; - ShareFile Edition アカウントが存在し、以下からログインできます。",
    "support": "ご不明の点があれば、<a href='https://support.sharefile.com/s/'>サポートに連絡</a>するか、1-800-921-4250 に電話してください"
  };
  _exports.rs3LoginRedirect = rs3LoginRedirect;
});