define("ember-rs/locales/nl/templates/accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.account = void 0;
  var account = {
    "settings": {
      "header": "Instellingen",
      "description": "Dit zijn de instellingen voor deze gebruiker en dit account.",
      "personal-info-header": "Uw informatie",
      "account-info": {
        "header": "Instellingen",
        "company-name-header": "Bedrijfsnaam",
        "current-plan-header": "Huidige abonnement",
        "account-name-header": "Accountnaam"
      },
      "verified-emails": {
        "header": "Instellingen",
        "header-description": "Voeg een van uw e-mailadressen toe waarnaar documenten kunnen worden verzonden. In uw dashboard worden alle documenten weergegeven die naar een van deze e-mailadressen zijn verzonden.",
        "add-email": "Geverifieerd e-mailadres toevoegen",
        "send-email": "Verificatie-e-mail verzenden",
        "enter-email": "Voer een e-mailadres in..."
      },
      "account-settings": {
        "header": "Instellingen",
        "enable-passcode-header": "Wachtwoordcode voor documenten vereisen",
        "enable-passcode-description": "Er is een wachtwoordcode vereist om het document te ondertekenen. Afzenders kunnen een wachtwoordcode genereren tijdens het verzenden voor een handtekening.",
        "enable-token-expiration-header": "Standaard verloopperiode token",
        "enable-token-expiration-description": "Stel de verlooptijd in voor alle via e-mail verzonden koppelingen naar documenten, waaronder koppelingen voor ondertekening en delen.",
        "enable-blue-ink-header": "Handtekeningen in blauwe inkt",
        "enable-blue-ink-description": "Alle vastgelegde handtekeningen op documenten die van dit account zijn verzonden, zijn blauw. Dit is bedoeld om een ​​ondertekend origineel van een fotokopie te onderscheiden.",
        "enable-integrations-description": "Sta al uw gebruikers toe om de door u gebruikte verbinding met andere apps te bekijken en in te stellen. Door deze optie uit te schakelen, kan geen van de gebruikers de integratie-instellingen bekijken om de standaardintegraties in of uit te schakelen. Dit is bedoeld om de toegang van al uw gebruikers tot de beschikbare integraties centraal te beheren.",
        "enable-signer-download-header": "Signer document download",
        "enable-signer-download-description": "Allow signers to download the original document before signing."
      },
      "mfa-settings": {
        "enable-mfa-header": "Verificatie in twee stappen",
        "enable-mfa-description": "Met verificatie in twee stappen kunnen gebruikers een telefoonnummer instellen om een code te ontvangen via sms of een gesproken bericht. Gebruikers registreren zich via het aanmeldingsproces.",
        "opt-out-mfa-description": "Verificatie in twee stappen wordt standaard vereist voor alle gebruikers. Als u zich wilt afmelden voor deze instelling, kunt u een vrijstelling invullen en vervolgens de instelling wijzigen.",
        "complete-mfa-waiver": "Afmeldingsverklaring voltooien",
        "opt-out-waiver-title": "Afmeldingsverklaring voor verificatie in twee stappen",
        "opt-out-waiver-description": "Citrix raadt <a href='https://support.citrix.com/article/CTX277910'>Verificatie in twee stappen</a> ten zeerste aan, een vorm van meervoudige verificatie (MFA) bij de RightSignature-service, als een extra beveiligingslaag om de kans op ongeautoriseerde toegang tot RightSignature-inhoud te verkleinen.",
        "opt-out-waiver-consent": "Door verificatie in twee stappen te weigeren voor dit RightSignature-account, verklaart u dat u bevoegd bent namens de entiteit die dit exemplaar van RightSignature in licentie heeft gegeven om de risico's en verplichtingen van het uitschakelen van verificatie in twee stappen op u te nemen.",
        "opt-out-quit": "Afsluiten",
        "opt-out-authority": "Ik ben bevoegd",
        "opt-out-title": "Kies de reden(en) waarom u niet wilt dat de inhoud van dit RightSignature-account door verificatie in twee stappen wordt beschermd:",
        "opt-out-reason-first": "We slaan geen gevoelige of vertrouwelijke gegevens op in ons RightSignature-account",
        "opt-out-reason-second": "We hebben niet de technische capaciteit om verificatie in twee stappen te gebruiken",
        "opt-out-description": "Door hieronder op de knop Verificatie in twee stappen afwijzen te klikken, verklaar ik dat ik het formulier te goeder trouw heb ingevuld en alle risico's en aansprakelijkheden op mij neem die verbonden zijn aan het niet gebruiken van verificatie in twee stappen.",
        "opt-out-decline": "Verificatie in twee stappen afwijzen",
        "opted-out": "{{optOutEmail}} heeft zich op {{optOutDate}} afgemeld voor verplichte naleving. Verificatie in twee stappen kan nog steeds worden in- of uitgeschakeld door op schakelknop hierboven te klikken.",
        "opted-out-no-context": "Van verplichte naleving wordt afgezien. Verificatie in twee stappen kan nog steeds worden in- of uitgeschakeld door op schakelknop hierboven te klikken.",
        "opted-out-toast": "Dit bevestigt dat u hebt geweigerd om de verplichte verificatie in twee stappen te implementeren voor gebruikers met dit account en dat u accepteert om de bijbehorende risico's te dragen namens uw organisatie."
      },
      "personal-settings": {
        "header": "Instellingen",
        "enable-field-detection-header": "Velddetectie gebruiken bij voorbereiding van document",
        "enable-field-detection-description": "Automatische velddetectie gebruiken bij het voorbereiden van documenten in plaats van het handmatig plaatsen van velden.",
        "enable-view-notification-header": "Melden wanneer een document is weergegeven",
        "enable-view-notification-description": "U ontvangt meldingen wanneer ondertekenaars een document hebben bekeken dat u ter ondertekening hebt verzonden.",
        "enable-signature-notification-header": "Melden wanneer een document is ondertekend",
        "enable-signature-notification-description": "U ontvangt meldingen telkens wanneer een ondertekenaar een document heeft ondertekend waarbij u een partij bent. (U ontvangt ook nog een melding wanneer het document is voltooid.)",
        "enable-document-complete-notification-header": "Melden wanneer een document is voltooid",
        "enable-document-complete-notification-description": "U ontvangt meldingen wanneer een document waarbij u een partij bent, is ondertekend.",
        "enable-contact-searching-header": "Contactpersonen zoeken",
        "enable-contact-searching-description": "Door Contactpersonen zoeken worden contactpersonen gesuggereerd terwijl u een naam typt bij het toevoegen van ondertekenaars op basis van eerder verzonden documenten en ingeschakelde integraties voor contactpersonen.",
        "default-expiration-setting-header": "Standaard verloopperiode",
        "default-expiration-setting-description": "Stel het standaard aantal dagen in voor het verlopen van het document. U kunt dit ook per document wijzigen wanneer u een nieuw document verzendt.",
        "enable-send-reminder-email-header": "E-mailherinneringen verzenden",
        "enable-send-reminder-email-description": "E-mail ontvangers van documenten wekelijks (op de 3e, 7e, 14e, 21e, 28e dag) of dagelijks (vanaf de 7e tot de 30e dag) als een document niet is ondertekend.",
        "enable-attach-completed-pdf-header": "Voltooide PDF in e-mails bijvoegen",
        "enable-attach-completed-pdf-description": "Het voltooide PDF wordt toegevoegd aan de voltooiingsmelding die naar alle partijen van het document wordt verzonden. PDF's die groter zijn dan 10 MB worden niet als bijlage toegevoegd.",
        "default-signer-setting-header": "Afzender standaard instellen als ondertekenaar",
        "default-signer-setting-description": "Wijs de afzender standaard een rol als 'ondertekenaar' toe voor alle documenten. U kunt dit ook per document wijzigen wanneer u een nieuw document verzendt.",
        "signature-types-settings-header": "Toegestane handtekeningtypen",
        "signature-types-settings-description": "Kies de handtekeningtypen die u accepteert van ondertekenaars van uw documenten.",
        "signature-type-drawn": "Getekend",
        "signature-type-typed": "Getypt",
        "signature-type-uploaded": "Uploaded",
        "signature-type-uploaded-description": "Only available in the New Signer experience. This feature is currently unavailable for Signature Annotations and for workflows like Sign in Person, Template Share Links, and Web Embed links."
      },
      "saved-signature-settings": {
        "header": "Instellingen",
        "no-signature-description": "Geen opgeslagen handtekeningen. Zodra u een document hebt ondertekend, worden uw handtekeningen hier opgeslagen en beheerd.",
        "signature-available-description": "De standaardhandtekening wordt gebruikt wanneer u op een handtekeningveld klikt. U kunt uw handtekening ook per document wijzigen wanneer u een nieuw document ondertekent.",
        "confirm-deletion": "Weet zeker dat u deze handtekening wilt verwijderen?",
        "default": "Standaard",
        "success": "De standaardhandtekening is ingesteld.",
        "error": "Fout bij het instellen van de standaardhandtekening."
      }
    },
    "integrations": {
      "header": "Instellingen",
      "description": "Dit zijn de instellingen voor deze gebruiker en dit account."
    },
    "branding": {
      "header": "Instellingen",
      "disabled": {
        "description": "Dit zijn de instellingen voor deze gebruiker en dit account."
      },
      "signing": {
        "description": "Dit zijn de instellingen voor deze gebruiker en dit account.",
        "background-color-header": "Achtergrondkleur:",
        "footer-color-header": "Kleur voettekst:",
        "header-options-header": "Opties voor koptekst:",
        "header-show-logo": "Uw logo weergeven",
        "header-no-logo": "Geen logo",
        "footer-options-header": "Opties voor voettekst:",
        "footer-option-send-name-only": "Alleen naam verzenden",
        "footer-option-company-name-only": "Alleen bedrijfsnaam",
        "footer-option-company-name-only-tooltip": "Voeg een bedrijfsnaam toe op het tabblad 'Account' hierboven.",
        "footer-document-name": "Documentnaam",
        "footer-from": "Van",
        "footer-at": "om",
        "footer-option-sender-and-company-name": "Afzender en bedrijfsnaam",
        "footer-option-show-sender-avatar": "Avator afzender weergeven"
      },
      "email": {
        "header": "Instellingen",
        "description": "Dit zijn de instellingen voor deze gebruiker en dit account.",
        "email-header": "E-mailkoptekst:",
        "show-sender-from": "Naam afzender bij 'Van' weergeven",
        "show-company-subject": "Bedrijfsnaam in onderwerp weergeven",
        "show-document-name-in-subject": "Document in onderwerp gebruiken",
        "custom-email-greeting-header": "Aangepaste e-mailbegroeting:",
        "custom-email-greeting-description": "Dit wordt als het eerste gedeelte van alle e-mails aan ondertekenaars weergegeven.",
        "custom-email-signature-header": "Aangepaste e-mailhandtekening:",
        "custom-email-signature-description": "Dit wordt als het laatste gedeelte van alle e-mails aan ondertekenaars weergegeven.",
        "mergefield-help": "U kunt de volgende samenvoegvelden gebruiken om aangepaste informatie aan uw e-mail toe te voegen:",
        "signer-name": "signer_name",
        "signer-first-name": "signer_first_name",
        "sender-name": "sender_name",
        "document-name": "document_name",
        "document-filename": "document_filename",
        "document-personalized-message": "document_personalized_message",
        "preview-from": "Van:",
        "preview-subject": "Onderwerp:",
        "preview-no-logo": "Geen logo",
        "preview-greeting": "Hallo",
        "preview-message": "heeft u het document '{{filename}}' gestuurd om te ondertekenen",
        "preview-description": "Volg deze koppeling om het document te controleren en te ondertekenen met een digitale handtekening:",
        "preview-action": "DOCUMENT CONTROLEREN"
      }
    },
    "users": {
      "trial-account-description": "Met uw huidige abonnement bent u niet in staat om gebruikers te beheren. Upgrade uw abonnement om toegang tot deze functie te krijgen.",
      "admin-section-header": "Gebruikers beheren",
      "admin-section-description": "Dit zijn de gebruikers van dit RightSignature&trade;-account. Als accountbeheerder kunt u gebruikers toevoegen, verwijderen en promoveren.",
      "non-admin-section-header": "Gebruikers",
      "maximum-seats-alert-text": "U hebt het maximale aantal gebruikers voor uw account bereikt. Verwijder een gebruiker als u een andere gebruiker wilt toevoegen of upgrade uw account om meer seats toe te voegen.",
      "seats-used": "<b>{{seatsUsed}}</b> seats van <b>{{totalSeats}}</b> in gebruik",
      "unknown-user": "(onbekende gebruiker)",
      "add-user-button-text": "Gebruiker toevoegen",
      "edit-user-button-text": "Gebruiker bewerken",
      "span-text-me": "IK",
      "span-text-owner": "EIGENAAR",
      "span-text-admin": "ADMIN",
      "add": "Toevoegen",
      "account-users-new_introduction": "Voer de naam en het e-mailadres van de persoon in die u aan dit account wilt toevoegen.",
      "first-name": "Voornaam...",
      "last-name": "Achternaam..."
    },
    "bills": {
      "header": "Instellingen",
      "contact": "Als u RightSignature-factuurontvangstbewijzen wilt bekijken en/of afdrukken, klikt u op de juiste downloadknop hieronder. <a href='https://support.sharefile.com/s/'>Bel ons</a> of <a href='https://support.sharefile.com/s/'>neem contact met ons op</a> als u nog vragen hebt.",
      "date": "Datum",
      "type": "Type",
      "status": "Status"
    },
    "api": {
      "header": "Instellingen",
      "description": "Dit zijn de instellingen voor deze gebruiker en dit account.",
      "primary-action": "Nieuwe sleutel aanvragen"
    }
  };
  _exports.account = account;
});