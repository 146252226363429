define("ember-rs/locales/fr/templates/accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.account = void 0;
  var account = {
    "settings": {
      "header": "Paramètres",
      "description": "Paramètres pour cet utilisateur et ce compte.",
      "personal-info-header": "Vos informations",
      "account-info": {
        "header": "Paramètres",
        "company-name-header": "Nom de la société",
        "current-plan-header": "Plan actuel",
        "account-name-header": "Nom de compte"
      },
      "verified-emails": {
        "header": "Paramètres",
        "header-description": "Ajoutez les adresses e-mail auxquelles les documents peuvent être envoyés. Votre tableau de bord affichera tous les documents envoyés à l'une de ces adresses e-mail.",
        "add-email": "Ajouter e-mail vérifié",
        "send-email": "Envoyer e-mail de vérification",
        "enter-email": "Entrez un e-mail..."
      },
      "account-settings": {
        "header": "Paramètres",
        "enable-passcode-header": "Exiger code d'accès pour les documents",
        "enable-passcode-description": "Un code d'accès sera requis pour signer le document. Les expéditeurs peuvent générer un code d’accès durant le processus d'envoi pour signature.",
        "enable-token-expiration-header": "Délai d'expiration du jeton par défaut",
        "enable-token-expiration-description": "Définissez le délai d'expiration de tous les liens aux documents envoyés par e-mail, y compris les liens de signature et les liens de partage.",
        "enable-blue-ink-header": "Signatures à l'encre bleue",
        "enable-blue-ink-description": "Toutes les signatures capturées sur les documents envoyés depuis ce compte seront bleues. Ceci permet de distinguer un original signé d'une photocopie.",
        "enable-integrations-description": "Autorisez tous vos utilisateurs à afficher et définir la connexion avec les autres applications que vous utilisez. Si vous désactivez cette option, aucun des utilisateurs ne pourra afficher les paramètres d'intégration pour activer/désactiver les intégrations prêtes à l'emploi. Ceci permet de contrôler de manière centralisée l'accès de tous vos utilisateurs aux intégrations disponibles.",
        "enable-signer-download-header": "Signer document download",
        "enable-signer-download-description": "Allow signers to download the original document before signing."
      },
      "mfa-settings": {
        "enable-mfa-header": "Validation en deux étapes",
        "enable-mfa-description": "La validation en deux étapes permet aux utilisateurs de configurer un numéro de téléphone pour recevoir un code via SMS ou message vocal. Les utilisateurs s'inscrivent via le processus de connexion.",
        "opt-out-mfa-description": "La validation en deux étapes est requise par défaut pour tous les utilisateurs. Si vous souhaitez désactiver ce paramètre, vous pouvez remplir une dérogation, puis modifier le paramètre.",
        "complete-mfa-waiver": "Compléter dérogation",
        "opt-out-waiver-title": "Document de renonciation à l'authentification à deux facteurs",
        "opt-out-waiver-description": "Citrix recommande fortement d'activer la <a href='https://support.citrix.com/article/CTX277910'>validation en deux étapes</a>, une forme d'authentification multifacteur (MFA), sur le service RightSignature comme couche de sécurité supplémentaire destinée à réduire le risque d'accès non autorisé au contenu RightSignature.",
        "opt-out-waiver-consent": "En refusant d'activer la validation en deux étapes pour ce compte RightSignature, vous certifiez que vous êtes autorisé, au nom de l'entité qui a concédé sous licence cette instance de RightSignature, à assumer les risques et les responsabilités liés à la désactivation de la validation en deux étapes.",
        "opt-out-quit": "Quitter",
        "opt-out-authority": "J'ai l'autorité",
        "opt-out-title": "Veuillez choisir la ou les raisons pour lesquelles vous ne souhaitez pas que le contenu de ce Compte RightSignature soit protégé par la validation en deux étapes :",
        "opt-out-reason-first": "Nous ne stockons pas de données sensibles ou confidentielles dans notre compte RightSignature",
        "opt-out-reason-second": "Nous ne disposons pas des capacités techniques nécessaires pour utiliser la validation en deux étapes",
        "opt-out-description": "En cliquant sur le bouton Décliner la validation en deux étapes ci-dessous, je certifie avoir rempli le formulaire de bonne foi et assume tous les risques et responsabilités associés à la non-utilisation de la validation en deux étapes.",
        "opt-out-decline": "Décliner la validation en deux étapes",
        "opted-out": "{{optOutEmail}} a décliné l'utilisation obligatoire le {{optOutDate}}. La validation en deux étapes peut toujours être activée/désactivée en cliquant sur le bouton ci-dessus.",
        "opted-out-no-context": "L'utilisation obligatoire a été déclinée. La validation en deux étapes peut toujours être activée/désactivée en cliquant sur le bouton ci-dessus.",
        "opted-out-toast": "Cela confirme que vous avez refusé de déployer la validation en deux étapes obligatoire pour les utilisateurs de ce compte et que vous avez accepté d'assumer les risques associés au nom de votre organisation."
      },
      "personal-settings": {
        "header": "Paramètres",
        "enable-field-detection-header": "Utiliser la détection de champ pour la préparation de documents",
        "enable-field-detection-description": "Utilisez la détection de champ automatique lors de la préparation de documents plutôt que de placer manuellement des champs.",
        "enable-view-notification-header": "Notifier lorsqu'un document a été consulté",
        "enable-view-notification-description": "Vous recevrez des notifications lorsque les signataires ont consulté un document que vous avez envoyé pour signature.",
        "enable-signature-notification-header": "Notifier lorsqu'un document a été signé",
        "enable-signature-notification-description": "Vous recevrez des notifications chaque fois qu'un signataire signe un document vous appartenant. (Vous serez notifié lorsque le document est complété)",
        "enable-document-complete-notification-header": "Notifier lorsqu'un document a été complété",
        "enable-document-complete-notification-description": "Vous recevrez des notifications chaque fois qu'un document vous appartenant a été exécuté.",
        "enable-contact-searching-header": "Recherche de contacts",
        "enable-contact-searching-description": "La recherche de contacts suggère des contacts à mesure que vous tapez un nom lors de l'ajout de signataires et se base pour cela sur les documents précédemment envoyés, ainsi que sur les intégrations de contacts activées.",
        "default-expiration-setting-header": "Délai d'expiration par défaut",
        "default-expiration-setting-description": "Spécifiez le nombre de jours par défaut après lequel le document expire. Vous pouvez également modifier ce paramètre par document lors de l'envoi d'un nouveau document.",
        "enable-send-reminder-email-header": "Envoyer e-mails de rappel",
        "enable-send-reminder-email-description": "Envoyez un e-mail aux destinataires des documents une fois par semaine (le 3, 7, 14, 21, 28ème jour) ou quotidiennement (du 7 au 30ème jour) si un document n'est pas signé.",
        "enable-attach-completed-pdf-header": "Joindre PDF complété aux e-mails",
        "enable-attach-completed-pdf-description": "Le PDF complété sera joint à la notification envoyée à toutes les parties au document. Les fichiers PDF de plus de 10 Mo ne seront pas inclus en tant que pièce jointe.",
        "default-signer-setting-header": "Expéditeur par défaut en tant que signataire",
        "default-signer-setting-description": "L'expéditeur par défaut détient le rôle de « signataire » sur tous les documents. Vous pouvez également modifier ce paramètre par document lors de l'envoi d'un nouveau document.",
        "signature-types-settings-header": "Types de signatures autorisées",
        "signature-types-settings-description": "Choisissez le ou les types de signatures que vous accepterez des signataires de vos documents.",
        "signature-type-drawn": "Manuscrite",
        "signature-type-typed": "Tapée",
        "signature-type-uploaded": "Uploaded",
        "signature-type-uploaded-description": "Only available in the New Signer experience. This feature is currently unavailable for Signature Annotations and for workflows like Sign in Person, Template Share Links, and Web Embed links."
      },
      "saved-signature-settings": {
        "header": "Paramètres",
        "no-signature-description": "Aucune signature enregistrée. Une fois que vous signez un document, vos signatures seront stockées ici à des fins de gestion.",
        "signature-available-description": "La signature par défaut sera renseignée lorsque vous cliquez sur un champ de signature. Vous pouvez également modifier votre signature pour chaque document lors de la signature d'un nouveau document.",
        "confirm-deletion": "Êtes-vous sûr de vouloir supprimer cette signature ?",
        "default": "Valeur par défaut",
        "success": "La signature par défaut a été définie.",
        "error": "Erreur lors de la définition de la signature par défaut."
      }
    },
    "integrations": {
      "header": "Paramètres",
      "description": "Paramètres pour cet utilisateur et ce compte."
    },
    "branding": {
      "header": "Paramètres",
      "disabled": {
        "description": "Paramètres pour cet utilisateur et ce compte."
      },
      "signing": {
        "description": "Paramètres pour cet utilisateur et ce compte.",
        "background-color-header": "Couleur d'arrière-plan :",
        "footer-color-header": "Couleur de pied de page :",
        "header-options-header": "Options d'en-tête :",
        "header-show-logo": "Afficher votre logo",
        "header-no-logo": "Pas de logo",
        "footer-options-header": "Options de pied de page :",
        "footer-option-send-name-only": "Envoyer nom uniquement",
        "footer-option-company-name-only": "Nom de l'entreprise uniquement",
        "footer-option-company-name-only-tooltip": "Ajoutez le nom d'une entreprise dans l'onglet Compte ci-dessus.",
        "footer-document-name": "Nom du document",
        "footer-from": "De",
        "footer-at": "à",
        "footer-option-sender-and-company-name": "Expéditeur &amp; nom de l'entreprise",
        "footer-option-show-sender-avatar": "Afficher avatar de l'expéditeur"
      },
      "email": {
        "header": "Paramètres",
        "description": "Paramètres pour cet utilisateur et ce compte.",
        "email-header": "En-tête de message :",
        "show-sender-from": "Afficher le nom de l'expéditeur depuis",
        "show-company-subject": "Afficher le nom de l'entreprise dans l'objet",
        "show-document-name-in-subject": "Utiliser document dans l'objet",
        "custom-email-greeting-header": "Message d'accueil personnalisé :",
        "custom-email-greeting-description": "S'affiche en tant que première section sur tous les e-mails du signataire.",
        "custom-email-signature-header": "Signature e-mail personnalisée :",
        "custom-email-signature-description": "S'affiche en tant que dernière section sur tous les e-mails du signataire.",
        "mergefield-help": "Vous pouvez utiliser les champs de fusion suivants pour ajouter des informations personnalisées à votre e-mail :",
        "signer-name": "signer_name",
        "signer-first-name": "signer_first_name",
        "sender-name": "sender_name",
        "document-name": "document_name",
        "document-filename": "document_filename",
        "document-personalized-message": "document_personalized_message",
        "preview-from": "Depuis :",
        "preview-subject": "Objet :",
        "preview-no-logo": "Pas de logo",
        "preview-greeting": "Bonjour",
        "preview-message": "vous a envoyé le document '{{filename}}' à signer",
        "preview-description": "Pour consulter le document et le signer de manière électronique, suivez ce lien :",
        "preview-action": "VÉRIFIER DOCUMENT"
      }
    },
    "users": {
      "trial-account-description": "Votre plan actuel ne vous permet pas de gérer d'utilisateurs. Mettez à niveau votre plan pour accéder à cette fonctionnalité.",
      "admin-section-header": "Gérer les utilisateurs",
      "admin-section-description": "Il s'agit des utilisateurs de ce compte RightSignature&trade;. En tant qu'administrateur de compte, vous pouvez ajouter, supprimer et promouvoir des utilisateurs.",
      "non-admin-section-header": "Utilisateurs",
      "maximum-seats-alert-text": "Vous avez atteint le nombre maximum d'utilisateurs pour votre compte. Si vous voulez ajouter un autre utilisateur, supprimez-en un ou mettez à niveau votre compte pour ajouter des utilisateurs supplémentaires.",
      "seats-used": "<b>{{seatsUsed}}</b> postes sur <b>{{totalSeats}}</b> utilisés",
      "unknown-user": "(utilisateur inconnu)",
      "add-user-button-text": "Ajouter un utilisateur",
      "edit-user-button-text": "Modifier l'utilisateur",
      "span-text-me": "MOI",
      "span-text-owner": "PROPRIÉTAIRE",
      "span-text-admin": "ADMIN",
      "add": "Ajouter",
      "account-users-new_introduction": "Entrez le nom et l'adresse e-mail de la personne que vous souhaitez ajouter à ce compte.",
      "first-name": "Prénom...",
      "last-name": "Nom..."
    },
    "bills": {
      "header": "Paramètres",
      "contact": "Pour afficher et/ou imprimer les relevés de facturation RightSignature, cliquez sur le bouton de téléchargement approprié ci-dessous. Veuillez <a href='https://support.sharefile.com/s/'>nous appeler</a> pour obtenir de l'aide ou <a href='https://support.sharefile.com/s/'>contactez-nous</a> si vous avez des questions supplémentaires.",
      "date": "Date",
      "type": "Type",
      "status": "État"
    },
    "api": {
      "header": "Paramètres",
      "description": "Paramètres pour cet utilisateur et ce compte.",
      "primary-action": "Demander une nouvelle clé"
    }
  };
  _exports.account = account;
});