define("ember-rs/locales/nl/templates/components/trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.trialModal = void 0;
  var trialModal = {
    "ohNo": "Oh nee!",
    "reminder": "Ter herinnering:",
    "trialExpired": "Uw gratis proefperiode is verlopen.",
    "trialEnds_singular": "Uw gratis proefperiode eindigt over {{trialDays}} dag.",
    "trialEnds_plural": "Uw gratis proefperiode eindigt over {{trialDays}} dagen.",
    "day": "dag",
    "upgradeMessage": "Moet u nog steeds documenten laten ondertekenen? RightSignature-abonnementen zijn zo geprijsd dat uw bedrijf tijd en geld kan besparen.",
    "accountSetup": "Eenvoudige installatie, geen contract — op elk moment opzeggen.",
    "trialSetup": "Behoud de documenten en accountinstellingen van uw proefversie.",
    "paperWork": "Bespaar elke week een uur of meer aan administratief werk!",
    "moreTime": "Hebt u meer tijd nodig voor uw evaluatie?",
    "call": "<a href='https://support.sharefile.com/s/'>Contact opnemen</a>",
    "logout": "Afmelden",
    "notSure": "Nog niet zeker? Kijk eens wat RightSignature voor uw bedrijf kan doen."
  };
  _exports.trialModal = trialModal;
});