define("ember-rs/locales/de/templates/components/hybris-trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisTrialModal = void 0;
  var hybrisTrialModal = {
    "ohNo": "Oje!",
    "reminder": "Nur zur Erinnerung:",
    "previewHasEnded": "Ihre Testversion von RightSignature ist abgelaufen.",
    "freeTrialEnds": "Ihre kostenlose Testversion endet in {{trialDaysRemaining}} Tag(en).",
    "needToGetThingsSigned": "Müssen Sie noch etwas unterschreiben lassen? Die neuen Abos für RightSignature sind ihren Preis wert.",
    "exploreAdminFeatures": "Probieren Sie die erweiterten Verwaltungs- und Sicherheitsfeatures aus",
    "retainDocuments": "Behalten Sie die Dokumente und Kontoeinstellungen der Originalversion bei",
    "easySetup": "Einfache Einrichtung, kein Vertrag -- jederzeit kündbar",
    "saveTimeAndPaper": "Sparen Sie jede Woche eine Stunde oder mehr an Verwaltungsarbeit",
    "choosePlan": "Wählen Sie ein Abo, um fortzufahren",
    "needAssitance": "Benötigen Sie Unterstützung bei Ihrer Bewertung?",
    "contactSupport": "<a href='https://support.sharefile.com/s/' class='rs-button is-link is-secondary'>Support kontaktieren</a>",
    "notSureYet": "Noch nicht sicher? Sehen Sie, was RightSignature für Ihr Unternehmen tun kann.",
    "logout": "Abmelden"
  };
  _exports.hybrisTrialModal = hybrisTrialModal;
});