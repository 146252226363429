define("ember-rs/locales/fr/templates/components/trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.trialModal = void 0;
  var trialModal = {
    "ohNo": "Oh non !",
    "reminder": "Rappel :",
    "trialExpired": "Votre version d'évaluation gratuite a expiré.",
    "trialEnds_singular": "Votre évaluation gratuite se termine dans {{trialDays}} jour.",
    "trialEnds_plural": "Votre évaluation gratuite se termine dans {{trialDays}} jours.",
    "day": "jour",
    "upgradeMessage": "Encore besoin de faire signer des documents ? Les tarifs des plans RightSignature sont fixés pour aider votre entreprise à économiser du temps et de l'argent.",
    "accountSetup": "Configuration facile, pas de contrat -- possibilité d'annuler à tout moment.",
    "trialSetup": "Conservez les documents et la configuration de compte utilisés lors de votre évaluation.",
    "paperWork": "Économisez une heure ou plus de tâches administratives chaque semaine !",
    "moreTime": "Besoin de plus de temps pour votre évaluation ?",
    "call": "<a href='https://support.sharefile.com/s/'>Nous contacter</a>",
    "logout": "Déconnexion",
    "notSure": "Pas encore sûr ? Découvrez ce que RightSignature peut apporter à votre entreprise."
  };
  _exports.trialModal = trialModal;
});