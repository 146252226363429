define("ember-rs/locales/fr/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notFound = void 0;
  var notFound = {
    "doesNotExist": "Cette page n'existe pas.",
    "didYou": "Avez-vous suivi un lien depuis un autre endroit que RightSignature&trade; ?",
    "anotherPart": "Si vous avez atteint cette page à partir d'une autre partie de RightSignature&trade;, <a href='https://support.sharefile.com/s/'>contactez le support</a> afin que nous puissions corriger notre erreur.",
    "followLink": "Avez-vous suivi un lien d'un autre site ?",
    "otherSite": "Les liens provenant d'autres sites peuvent parfois être obsolètes ou mal orthographiés. <a href='https://support.sharefile.com/s/'>Contactez le support</a> et dites-nous sur quel lien vous avez cliqué afin que nous puissions essayer de contacter l'autre site et résoudre le problème.",
    "typeUrl": "Avez-vous tapé l'URL ?",
    "doubleCheck": "Vous avez peut-être incorrectement saisi l'adresse (URL). Vérifiez que vous avez respecté l'orthographe, les majuscules, etc.",
    "home": "Accueil"
  };
  _exports.notFound = notFound;
});