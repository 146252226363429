define("ember-rs/locales/fr/templates/components/review-and-send-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reviewAndSendItem = void 0;
  var reviewAndSendItem = {
    "passcode-message": "N'oubliez pas ! Vous devez envoyer ce code PIN aux signataires du document.",
    "bulk-send-passcode-message": "Vous avez activé le code d'accès pour les transactions de documents. L'envoi en bloc ne prend pas en charge cela actuellement.",
    "generate-new-pin": "Générer nouveau code PIN",
    "pin-specification": "Le code PIN doit être un numéro à cinq chiffres.",
    "expiration-message": "Après la date d'expiration, le document ne sera plus disponible pour signature.",
    "ExpiresIn": "Expire dans",
    "days": "jours",
    "loading": "chargement",
    "page": "page(s)",
    "emailTip1": "Un lien permettant de consulter ce document sera envoyé à {{{email}}}.",
    "emailTip2": "Le destinataire pourra consulter et télécharger ce document.",
    "enterEmail": "Entrer une adresse e-mail",
    "shareDoc": "Entrez l'adresse e-mail pour partager ce document",
    "ccMsg": "Ajoutez en Cc les parties pour leur donner un accès en lecture seule au document. Elles recevront des notifications, elles pourront voir les progrès et télécharger le document complété. Vous pouvez révoquer leur accès ultérieurement si nécessaire.",
    "add": "ajouter",
    "roles": "rôles",
    "recipients": "destinataires",
    "cc": "cc : ",
    "ccCaps": "CC",
    "messageLimit": "Le message ne peut pas contenir plus de 100 000 caractères.",
    "addMsg": "Ajouter un message personnalisé...",
    "for": "pour",
    "message": "message",
    "pin": "PIN",
    "tags": "balises",
    "passcodeOn": "Code d'accès activé",
    "passcodeOff": "Code d'accès désactivé",
    "restrictedFeatureMsg": "Votre plan actuel ne vous permet pas d'ajouter des codes d'accès aux documents. Mettez à niveau votre plan pour accéder à cette fonctionnalité.",
    "kba": "KBA",
    "on": "Activé",
    "off": "Désactivé",
    "kbaTip": "Exigez des signataires qu'ils vérifient leur identité avec un questionnaire de connaissances. Chaque signataire devra vérifier son identité de façon indépendante avant de signer le document. L'accès au document sera bloqué après 3 tentatives infructueuses.",
    "enable": "Activer",
    "addTag": "Ajouter une balise",
    "tagTip": "Ajoutez des balises à ce document pour le retrouver facilement plus tard. Vous pouvez également ajouter des valeurs aux balises, par exemple \"id_client : 1234\"",
    "addSalesforceOpportunity": "Ajouter une opportunité Salesforce",
    "addSalesforceOpportunityMsg": "Ajouter une opportunité Salesforce à ce document",
    "exceededKbaLimit": "Limite KBA atteinte",
    "exceededKbaMsg": "Vous avez atteint la limite mensuelle de votre abonnement actuel pour les documents compatibles avec KBA. Pour envoyer plus de documents avec la fonctionnalité d'authentification basée sur les connaissances de RightSignature activée, veuillez <a href='https://support.sharefile.com/s/'>contacter le support</a> pour mettre à niveau votre compte.",
    "tagName": "Nom de la balise...",
    "tagValue": "Valeur (facultative)",
    "sendTo": "Envoyer à",
    "name": "Nom",
    "docName": "Nom du document...",
    "totalNumberOfSigners": "Nombre total de signataires :",
    "expiresIn": "La période d'expiration doit être comprise entre 1 et 365 jours."
  };
  _exports.reviewAndSendItem = reviewAndSendItem;
});