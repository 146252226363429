define("ember-rs/locales/zh-cn/templates/components/hybris-trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisTrialModal = void 0;
  var hybrisTrialModal = {
    "ohNo": "糟糕!",
    "reminder": "温馨提示:",
    "previewHasEnded": "您使用最新版本的 RightSignature 的预览期已结束。",
    "freeTrialEnds": "您的免费试用将于 {{trialDaysRemaining}} 天后结束。",
    "needToGetThingsSigned": "还需要签名吗? 续订的 RightSignature 业务计划的定价是为了提供更高的价值。",
    "exploreAdminFeatures": "探索高级管理和安全功能",
    "retainDocuments": "保留原始版本的文档和帐户设置",
    "easySetup": "轻松设置，无合同 — 随时取消",
    "saveTimeAndPaper": "每周节省一小时或更长时间的文书工作时间",
    "choosePlan": "选择计划以继续",
    "needAssitance": "需要评估方面的帮助吗?",
    "contactSupport": "请<a href='https://support.sharefile.com/s/' class='rs-button is-link is-secondary'>联系支持人员</a>",
    "notSureYet": "还不确定? 了解 RightSignature 可以为您的业务做些什么。",
    "logout": "注销"
  };
  _exports.hybrisTrialModal = hybrisTrialModal;
});