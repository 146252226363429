define("ember-rs/locales/es/templates/components/hybris-trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisTrialModal = void 0;
  var hybrisTrialModal = {
    "ohNo": "¡Oh, no!",
    "reminder": "Un pequeño recordatorio:",
    "previewHasEnded": "El período de prueba de la versión más reciente de RightSignature ha finalizado.",
    "freeTrialEnds": "La prueba gratuita finaliza en {{trialDaysRemaining}} d.",
    "needToGetThingsSigned": "¿Todavía necesita firmar documentos? Los nuevos planes de RightSignature se adaptan para ofrecer un valor añadido.",
    "exploreAdminFeatures": "Explore funciones avanzadas de seguridad y administración",
    "retainDocuments": "Conserve los documentos y la configuración de cuenta de la versión original",
    "easySetup": "Instalación sencilla, sin contratos ni permanencia",
    "saveTimeAndPaper": "Ahórrese como mínimo una hora de papeleo a la semana",
    "choosePlan": "Elija un plan para continuar",
    "needAssitance": "¿Necesita ayuda?",
    "contactSupport": "<a href='https://support.sharefile.com/s/' class='rs-button is-link is-secondary'>Contactar con asistencia</a>",
    "notSureYet": "¿Todavía no lo tiene claro? Vea cómo RightSignature puede beneficiar a su negocio.",
    "logout": "Cerrar sesión"
  };
  _exports.hybrisTrialModal = hybrisTrialModal;
});