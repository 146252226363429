define("ember-rs/locales/fr/templates/terms-of-use-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.termsOfUseLink = void 0;
  var termsOfUseLink = {
    "terms": "Conditions d'utilisation",
    "url": "https://www.progress.com/legal"
  };
  _exports.termsOfUseLink = termsOfUseLink;
});