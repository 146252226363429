define("ember-rs/mixins/current-user-route", ["exports", "ember-rs/config/environment", "ember-rs/utils/safe-access", "ember-rs/mixins/partial-session"], function (_exports, _environment, _safeAccess, _partialSession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_partialSession.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    redirectToken: Ember.inject.service(),
    session: Ember.inject.service(),
    moment: Ember.inject.service(),
    store: Ember.inject.service(),
    partialSession: Ember.inject.service(),
    i18n: Ember.inject.service(),
    featureFlagService: Ember.inject.service(),
    ////////////////////////  Public API  ////////////////////////
    getCurrentUser: function getCurrentUser(params, transition) {
      var _this = this;

      var redirectToken = this.redirectToken;
      var partialSession = this.partialSession;

      if (this.get('session.isAuthenticated')) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          _this.store.findRecord('sessionCredential', 'current').then(function (sessionCredential) {
            if (redirectToken.hasValue()) {
              // clear the token
              redirectToken.setValue(null);
            }

            sessionCredential.get("user").then(function (user) {
              _this.currentUser.set('model', user); // Sets timezone


              if (_this.get('currentUser.model.timezone')) {
                _this.moment.changeTimeZone(_this.get('currentUser.model.timezone'));
              }

              if (partialSession.hasValue()) {
                partialSession.clearSession();
              }

              if (window.pendo) {
                window.pendo.initialize(JSON.parse(JSON.stringify({
                  apiKey: _environment.default.pendo.apiKey,
                  visitor: {
                    id: _environment.default.environment + '-' + user.id,
                    Timezone: _this.get('currentUser.model.timezone'),
                    SharefileUserId: _this.get('currentUser.sharefileUserId')
                  },
                  account: {
                    id: _environment.default.environment + '-' + _this.get('currentUser.accountId'),
                    SharefileAccountId: _this.get('currentUser.sharefileAccountId'),
                    Plan: _this.get('currentUser.account.planName'),
                    IsCar: _this.get('currentUser.account.isCar'),
                    BillingType: _this.get('currentUser.account.isHybris') ? "Hybris" : "Legacy",
                    TrialType: _this.get('currentUser.account.isHybrisTrial') ? "Hybris Trial" : _this.get('currentUser.account.isTrial') ? "Legacy Trial" : "Not Trial",
                    TrialExpiresAt: _this.get('currentUser.account.trialExpiresAt') || _this.get('currentUser.account.hybrisTrialExpiresAt'),
                    WorkspaceEnabled: _this.get('currentUser.account.isWorkspaceEnabled'),
                    IsMigrated: _this.get('currentUser.account.isMigrated'),
                    ControlPlane: sessionCredential.get('appcp'),
                    MfaEnabled: _this.get('currentUser.account.isMfaEnabledForEmployee'),
                    Locale: _this.get('i18n.locale')
                  }
                })));
              }

              _this.featureFlagService.setFFUser().then(function () {
                resolve(user);
              });
            });
          }, function (response) {
            var statusCode = (0, _safeAccess.default)(response, '500', 'errors', 0, 'status');

            if (statusCode === '417' && redirectToken.hasValue()) {
              // user is not connected to the token
              resolve(null); // just use the token
            } else {
              _this.invalidateSession();

              reject();
            }
          });
        }, 'currentSessionCredential');
      } else if (redirectToken.hasValue()) {
        // redirectToken takes over
        return new Ember.RSVP.resolve(null); // just use the toke
      } else if (partialSession.hasValue()) {
        return partialSession.getCurrentUser();
      } else {
        if (Ember.isPresent(transition) && Ember.isPresent(transition.intent) && Ember.isPresent(transition.intent.url)) {
          this.session.set('data.redirectUrl', transition.intent.url);
        }

        return this.transitionTo('login');
      }
    },
    clearUserSession: function clearUserSession() {
      this.currentUser.setProperties({
        'model': null
      });
    },
    invalidateSession: function invalidateSession() {
      var _this2 = this;

      Ember.run.once(this, function () {
        _this2.clearUserSession();

        if (_this2.get('session.isAuthenticated')) {
          // Never hits promise here because of `sessionInvalidationSucceeded` in application route
          _this2.session.invalidate();
        } else {
          _this2.replaceWith('unauthorized.unauthorized');
        }
      });
    }
  });

  _exports.default = _default;
});