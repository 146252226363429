define("ember-rs/locales/pt-br/templates/start-document/-send-for-signature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendForSignature = void 0;
  var sendForSignature = {
    "support": "<a href='https://support.sharefile.com/s/'>Entre em contato com o Suporte</a> para obter ajuda."
  };
  _exports.sendForSignature = sendForSignature;
});