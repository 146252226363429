define("ember-rs/locales/ja/templates/components/trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.trialModal = void 0;
  var trialModal = {
    "ohNo": "申し訳ございません。",
    "reminder": "ご一読ください。",
    "trialExpired": "無料トライアルの有効期限が切れました。",
    "trialEnds_singular": "無料トライアル期間は {{trialDays}} 日後に終了します 。",
    "trialEnds_plural": "無料トライアル期間は {{trialDays}} 日後に終了します 。",
    "day": "日",
    "upgradeMessage": "引き続き使用されますか? RightSignature の各プランは、業務時間の短縮とコストダウンを実現するために価格設定されています。",
    "accountSetup": "簡単にセットアップでき、契約を必要とせず、いつでもキャンセルできます。",
    "trialSetup": "トライアルのドキュメントおよびアカウント設定が保持されます。",
    "paperWork": "書類の処理にかかる時間を毎週 1 時間以上削減できます。",
    "moreTime": "評価期間を延長する場合。",
    "call": "<a href='https://support.sharefile.com/s/'>ご連絡ください。</a>",
    "logout": "ログアウト",
    "notSure": "ご検討中ですか? RightSignature がお客様のビジネスにどのように役立つかをご確認ください。"
  };
  _exports.trialModal = trialModal;
});