define("ember-rs/locales/ja/templates/unauthorized/no-integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.noIntegrations = void 0;
  var noIntegrations = {
    "noIntegrations": "ご利用中の ShareFile プランは、電子署名の統合をサポートしません",
    "support": "ご不明の点があれば、<a href='https://support.sharefile.com/s/'>サポートに連絡</a>するか、1-800-921-4250 に電話してください"
  };
  _exports.noIntegrations = noIntegrations;
});