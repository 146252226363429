define("ember-rs/locales/pt-br/templates/components/trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.trialModal = void 0;
  var trialModal = {
    "ohNo": "Oh, não!",
    "reminder": "Um lembrete:",
    "trialExpired": "Sua avaliação gratuita expirou.",
    "trialEnds_singular": "Sua avaliação gratuita termina em {{trialDays}} dia.",
    "trialEnds_plural": "Sua avaliação gratuita termina em {{trialDays}} dias.",
    "day": "dia",
    "upgradeMessage": "Ainda precisa assinar documentos? Com os planos do RightSignature você economiza tempo e dinheiro para o seu negócio.",
    "accountSetup": "Configuração fácil, sem contrato -- cancele a qualquer momento.",
    "trialSetup": "Mantenha os documentos e a configuração da conta da sua avaliação.",
    "paperWork": "Economize uma hora ou mais de papelada toda semana!",
    "moreTime": "Precisa de mais tempo com sua avaliação?",
    "call": "<a href='https://support.sharefile.com/s/'>Ligue para nós</a>",
    "logout": "Logout",
    "notSure": "Ainda não está convencido? Veja o que o RightSignature pode fazer pela sua empresa."
  };
  _exports.trialModal = trialModal;
});