define("ember-rs/locales/es/templates/onboarding/-entitlement-options-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.entitlementOptionsAdmin = void 0;
  var entitlementOptionsAdmin = {
    "title": "Bienvenido a RightSignature",
    "hasSeats": "Actualmente tiene privilegios de administrador para esta cuenta. Eso significa que puede otorgar acceso y funciones PRO a usted y a otros usuarios. Haga clic en el botón a continuación para otorgarse este acceso a usted mismo.",
    "notNow": "Quizá más tarde",
    "entitleMe": "Obtener acceso PRO",
    "noSeats": "¡Todas las licencias de esta cuenta están ya en uso! Puede quitar a alguien o conseguir más licencias <a href='https://support.sharefile.com/s/'>contactando con la Asistencia técnica</a>, o llamando al <a href='tel:+18009214250'>1-800-921-4250</a>, o simplemente puede continuar sin algunas funciones.",
    "proceed": "Continuar"
  };
  _exports.entitlementOptionsAdmin = entitlementOptionsAdmin;
});