define("ember-rs/locales/fr/templates/components/hybris-trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisTrialModal = void 0;
  var hybrisTrialModal = {
    "ohNo": "Oh non !",
    "reminder": "Rappel :",
    "previewHasEnded": "Votre période d'évaluation de la dernière version de RightSignature a pris fin.",
    "freeTrialEnds": "Votre évaluation gratuite se termine dans {{trialDaysRemaining}} jour(s).",
    "needToGetThingsSigned": "Encore besoin de faire signer des documents ? Les nouveaux tarifs des plans Business de RightSignature sont fixés de manière à offrir davantage de valeur.",
    "exploreAdminFeatures": "Explorez les fonctions d'administration et de sécurité avancées",
    "retainDocuments": "Conservez les documents et la configuration du compte de la version originale",
    "easySetup": "Configuration facile, pas de contrat — possibilité d'annuler à tout moment",
    "saveTimeAndPaper": "Économisez une heure ou plus de tâches administratives chaque semaine",
    "choosePlan": "Choisissez un plan pour continuer",
    "needAssitance": "Besoin d'aide avec votre évaluation ?",
    "contactSupport": "<a href='https://support.sharefile.com/s/' class='rs-button is-link is-secondary'>Contactez le support</a>",
    "notSureYet": "Pas encore sûr ? Découvrez ce que RightSignature peut apporter à votre entreprise.",
    "logout": "Déconnexion"
  };
  _exports.hybrisTrialModal = hybrisTrialModal;
});