define("ember-rs/locales/nl/templates/support-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportContent = void 0;
  var supportContent = {
    "needHelp": "Hulp nodig met RightSignature&trade;?",
    "articlesBelow": "Hieronder vindt u ondersteuningsartikelen en video's waarin veel van de vragen die u mogelijk hebt over RightSignature&trade; worden beantwoord. Als uw vraag niet in de artikelen en video's wordt beantwoord, neem dan gerust contact op met onze supportmedewerkers.",
    "supportArticles": "Ondersteuningsartikelen",
    "overview": "Overzicht van RightSignature&trade; voor ShareFile",
    "activate": "Hoe activeer ik de functie RightSignature&trade; voor ShareFile in mijn ShareFile-account?",
    "send": "Hoe stuur ik een document vanuit ShareFile met RightSignature voor ShareFile?",
    "sign": "Hoe onderteken ik een document in ShareFile met RightSignature voor ShareFile?",
    "branding": "Hoe configureer ik mijn bedrijfshuisstijl?",
    "changeEmail": "Hoe wijzig ik het e-mailadres dat gekoppeld is aan mijn RightSignature&trade;-account?",
    "addUser": "Hoe voeg ik gebruikers toe aan mijn RightSignature&trade;-account?",
    "signerOrder": "Hoe wijs ik een volgorde voor ondertekenaars van hetzelfde document toe?",
    "annotate": "Hoe kan ik met RightSignature&trade; aantekeningen in documenten maken voor mijn ondertekenaars?",
    "mergeFields": "Hoe gebruik ik samenvoegvelden om voorafgaand aan het verzenden tekst in een document in te voeren?",
    "passcode": "Hoe voeg ik een wachtwoordcode toe aan mijn document?",
    "importOverlay": "Hoe gebruik ik de functie Overlay importeren?",
    "docLocation": "Waar worden documenten met behulp van RightSignature&trade; voor ShareFile opgeslagen?",
    "share": "Hoe deel ik documenten?",
    "multiple": "Hoe verzend ik meerdere documenten voor ondertekening?",
    "oneOff": "Hoe verzend ik een document? (eenmalig)",
    "template": "Hoe maak ik een herbruikbare sjabloon?",
    "editTemplate": "Hoe bewerk ik een sjabloon?",
    "sendTemplate": "Hoe verzend ik een op een sjabloon gebaseerd document?",
    "embed": "Hoe kan ik een document voor ondertekening in mijn website insluiten?",
    "generate": "Hoe genereer ik een koppeling voor het delen voor mijn sjabloongebaseerde documenten?",
    "delete": "Hoe verwijder ik een document?",
    "tag": "Hoe voeg ik een tag toe aan een document?",
    "selfSign": "Hoe onderteken ik mijn eigen document?",
    "reports": "Welke rapportagefuncties en statistieken zijn beschikbaar bij RightSignature&trade;?",
    "complete": "Hoe moet u het naar u verzonden document voltooien?",
    "remind": "Hoe verzend ik een herinnering om een ​​document te ondertekenen?",
    "inPerson": "Hoe laat ik een document persoonlijk ondertekenen?",
    "showLess": "Minder weergeven",
    "showMore": "Meer weergeven",
    "sendQuestion": "Need Help? Visit our Help Center",
    "submitRequest": "Connect with our Support Team",
    "call": "Bel ons nu",
    "free": "Gratis"
  };
  _exports.supportContent = supportContent;
});