define("ember-rs/locales/fr/templates/onboarding/-agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "title": "Conditions d'utilisation",
    "body": "En utilisant RightSignature&trade; vous certifiez avoir lu et accepté nos {{partial 'terms-of-use-link'}} et notre <a href='{{progressHomePage}}/legal/privacy-center' target='_blank'>politique de confidentialité</a>.",
    "noThanks": "Non merci",
    "iAgree": "J'accepte"
  };
  _exports.agreeToTerms = agreeToTerms;
});