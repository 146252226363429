define("ember-rs/locales/pt-br/templates/accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.account = void 0;
  var account = {
    "settings": {
      "header": "Configurações",
      "description": "Estas são as configurações deste usuário e conta.",
      "personal-info-header": "Suas informações",
      "account-info": {
        "header": "Configurações",
        "company-name-header": "Nome da empresa",
        "current-plan-header": "Plano atual",
        "account-name-header": "Nome da conta"
      },
      "verified-emails": {
        "header": "Configurações",
        "header-description": "Adicione um dos seus endereços de email para os quais podem ser enviados documentos. Seu painel exibirá todos os documentos enviados para qualquer um desses endereços de e-mail.",
        "add-email": "Adicionar endereço de email",
        "send-email": "Enviar email de verificação",
        "enter-email": "Digite um email..."
      },
      "account-settings": {
        "header": "Configurações",
        "enable-passcode-header": "Exigir senha para documentos",
        "enable-passcode-description": "O código de acesso será necessário para assinar o documento. Os remetentes podem gerar código de acesso durante o processo de envio para assinatura.",
        "enable-token-expiration-header": "Período de expiração de token padrão",
        "enable-token-expiration-description": "Defina a duração de expiração de todos os links enviados por email para documentos, incluindo links de assinatura e links de compartilhamento.",
        "enable-blue-ink-header": "Assinaturas de tinta azul",
        "enable-blue-ink-description": "Todas as assinaturas capturadas nos documentos enviados desta conta serão azuis. Isso facilita a distinção entre um original assinado e uma fotocópia.",
        "enable-integrations-description": "Permitir que todos os usuários visualizem e definam a conexão com outros aplicativos que você usa. Ao desativá-lo, nenhum dos usuários poderá visualizar as configurações de Integrações para habilitar/desabilitar as integrações prontas para uso. Isso ajuda a controlar de modo centralizado o acesso de todos os usuários às integrações disponíveis.",
        "enable-signer-download-header": "Signer document download",
        "enable-signer-download-description": "Allow signers to download the original document before signing."
      },
      "mfa-settings": {
        "enable-mfa-header": "Verificação em duas etapas",
        "enable-mfa-description": "A verificação em duas etapas permite que os usuários configurem um número de telefone para receber um código via SMS ou voz. Os usuários se registram através do processo de login.",
        "opt-out-mfa-description": "A verificação em duas etapas é exigida por padrão para todos os usuários. Se você deseja optar por não participar dessa configuração, pode preencher uma renúncia e alterá-la.",
        "complete-mfa-waiver": "Renúncia completa",
        "opt-out-waiver-title": "Renúncia à utilização de autenticação de dois fatores",
        "opt-out-waiver-description": "A Citrix recomenda enfaticamente <a href='https://support.citrix.com/article/CTX277910'>Verificação em duas etapas</a> , uma forma de autenticação multifator (MFA), no serviço RightSignature como uma camada adicional de segurança para reduzir a probabilidade de acesso não autorizado ao conteúdo de RightSignature.",
        "opt-out-waiver-consent": "Com a sua recusa em ativar a verificação em duas etapas para esta conta do RightSignature, você certifica que detém autoridade, em nome da pessoa jurídica que licenciou essa instância do RightSignature, para assumir os riscos e responsabilidades de desativar a verificação em duas etapas.",
        "opt-out-quit": "Sair",
        "opt-out-authority": "Eu tenho autoridade",
        "opt-out-title": "Escolha os motivos pelos quais você não deseja que o conteúdo desta conta da RightSignature seja protegido pela verificação em duas etapas:",
        "opt-out-reason-first": "Nós não armazenamos dados confidenciais ou confidenciais em nossa conta RightSignature",
        "opt-out-reason-second": "Não temos capacidade técnica para usar a verificação em duas etapas",
        "opt-out-description": "Ao clicar no botão Recusar Verificação em duas etapas abaixo, certifico que preenchi o formulário de boa-fé e assumo todos os riscos e responsabilidades relativas à não utilização da verificação em duas etapas.",
        "opt-out-decline": "Recusar a verificação em duas etapas",
        "opted-out": "{{optOutEmail}} recusou atualização obrigatória em {{optOutDate}}. A verificação em duas etapas ainda pode ser ativada/desativada clicando no botão de alternância acima.",
        "opted-out-no-context": "A execução obrigatória foi recusada. A verificação em duas etapas ainda pode ser ativada/desativada clicando no botão de alternância acima.",
        "opted-out-toast": "Isso confirma que você se recusou a implantar a verificação em duas etapas obrigatoriamente para os usuários dessa conta e que concordou em arcar com os riscos associados em nome da sua organização."
      },
      "personal-settings": {
        "header": "Configurações",
        "enable-field-detection-header": "Usar detecção de campo para preparação de documentos",
        "enable-field-detection-description": "Use a detecção automática de campo ao preparar documentos em vez de colocar campos manualmente.",
        "enable-view-notification-header": "Notificar quando um documento tiver sido visto",
        "enable-view-notification-description": "As notificações serão enviadas para você quando os signatários tiverem visto um documento que você enviou para assinatura.",
        "enable-signature-notification-header": "Notificar quando um documento tiver sido assinado",
        "enable-signature-notification-description": "As notificações serão enviadas a você sempre que um signatário assinar um documento no qual você é parte. (Você ainda receberá notificação após a conclusão do documento.)",
        "enable-document-complete-notification-header": "Notificar quando um documento tiver sido preenchido",
        "enable-document-complete-notification-description": "As notificações serão enviadas para você quando um documento do qual você é parte estiver concluído.",
        "enable-contact-searching-header": "Busca de contatos",
        "enable-contact-searching-description": "A busca de contatos sugerirá contatos à medida que você digitar um nome ao adicionar signatários com base em documentos enviados anteriormente, além das integrações de contato ativadas.",
        "default-expiration-setting-header": "Período de expiração padrão",
        "default-expiration-setting-description": "Defina o número padrão de dias para a expiração do documento. Você também pode modificá-lo por documento ao enviar um novo documento.",
        "enable-send-reminder-email-header": "Enviar emails de lembrete",
        "enable-send-reminder-email-description": "Enviar email para destinatários de documentos semanalmente (no 3º, 7º, 14º, 21, 28º dia) ou diariamente (começando no 7º dia até o 30º dia) se um documento permanecer sem assinatura.",
        "enable-attach-completed-pdf-header": "Anexar PDF preenchido em emails",
        "enable-attach-completed-pdf-description": "O PDF preenchido será anexado à notificação completa que é enviada a todas as partes quando o documento é preenchido. PDFs maiores que 10 MB não serão incluídos como anexo.",
        "default-signer-setting-header": "Remetente padrão como signatário",
        "default-signer-setting-description": "Tornar o remetente um 'signatário' em todos os documentos como padrão. Você também pode modificar isso por documento ao enviar um novo documento.",
        "signature-types-settings-header": "Tipos de assinatura permitidos",
        "signature-types-settings-description": "Escolha o(s) tipo(s) de assinaturas que você aceitará dos signatários em seus documentos.",
        "signature-type-drawn": "Desenhada",
        "signature-type-typed": "Digitada",
        "signature-type-uploaded": "Uploaded",
        "signature-type-uploaded-description": "Only available in the New Signer experience. This feature is currently unavailable for Signature Annotations and for workflows like Sign in Person, Template Share Links, and Web Embed links."
      },
      "saved-signature-settings": {
        "header": "Configurações",
        "no-signature-description": "Nenhuma assinatura salva. Depois de assinar um documento, suas assinaturas serão armazenadas aqui para que você as administre.",
        "signature-available-description": "A assinatura padrão será colocada quando você clicar em um campo de assinatura. Você também pode modificar sua assinatura por documento ao assinar um novo documento.",
        "confirm-deletion": "Tem certeza de que deseja excluir esta assinatura?",
        "default": "Padrão",
        "success": "A assinatura padrão foi definida.",
        "error": "Erro ao definir a assinatura padrão."
      }
    },
    "integrations": {
      "header": "Configurações",
      "description": "Estas são as configurações deste usuário e conta."
    },
    "branding": {
      "header": "Configurações",
      "disabled": {
        "description": "Estas são as configurações deste usuário e conta."
      },
      "signing": {
        "description": "Estas são as configurações deste usuário e conta.",
        "background-color-header": "Cor de fundo:",
        "footer-color-header": "Cor do rodapé:",
        "header-options-header": "Opções de cabeçalho:",
        "header-show-logo": "Mostrar o seu logotipo",
        "header-no-logo": "Sem logotipo",
        "footer-options-header": "Opções de rodapé:",
        "footer-option-send-name-only": "Enviar apenas nome",
        "footer-option-company-name-only": "Apenas o nome da empresa",
        "footer-option-company-name-only-tooltip": "Adicione um nome de empresa na guia 'Conta' acima.",
        "footer-document-name": "Nome do documento",
        "footer-from": "De",
        "footer-at": "às",
        "footer-option-sender-and-company-name": "Remetente e nome da empresa",
        "footer-option-show-sender-avatar": "Exibir avatar do remetente"
      },
      "email": {
        "header": "Configurações",
        "description": "Estas são as configurações deste usuário e conta.",
        "email-header": "Cabeçalho de email:",
        "show-sender-from": "Mostrar o nome do remetente no campo 'de'",
        "show-company-subject": "Mostrar o nome da empresa no assunto",
        "show-document-name-in-subject": "Usar documento no objeto",
        "custom-email-greeting-header": "Saudação de email personalizada:",
        "custom-email-greeting-description": "Isso será exibido como a primeira seção em todos os emails do signatário.",
        "custom-email-signature-header": "Assinatura de email personalizada:",
        "custom-email-signature-description": "Isto será mostrado como a última seção em todos os emails do signatário.",
        "mergefield-help": "Você pode usar os seguintes campos de mesclagem para adicionar informações personalizadas ao seu email:",
        "signer-name": "signer_name",
        "signer-first-name": "signer_first_name",
        "sender-name": "sender_name",
        "document-name": "document_name",
        "document-filename": "document_filename",
        "document-personalized-message": "document_personalized_message",
        "preview-from": "De:",
        "preview-subject": "Assunto:",
        "preview-no-logo": "Sem logotipo",
        "preview-greeting": "Olá",
        "preview-message": "enviou-lhe o documento '{{filename}}' para assinar",
        "preview-description": "Para consultar o documento e assinar com uma assinatura eletrônica, siga este link:",
        "preview-action": "CONSULTAR DOCUMENTO"
      }
    },
    "users": {
      "trial-account-description": "Seu plano atual não permite que você gerencie usuários. Atualize seu plano para ter acesso a esse recurso.",
      "admin-section-header": "Gerenciar usuários",
      "admin-section-description": "Estes são os usuários nesta conta do RightSignature&trade;. Como administrador de conta, você pode adicionar, remover e promover usuários.",
      "non-admin-section-header": "Usuários",
      "maximum-seats-alert-text": "Você atingiu o número máximo de usuários para sua conta. Remova um usuário se quiser adicionar outro ou atualizar sua conta para adicionar mais assentos.",
      "seats-used": "<b>{{seatsUsed}}</b> de <b>{{totalSeats}}</b> assentos usados",
      "unknown-user": "(usuário desconhecido)",
      "add-user-button-text": "Adicionar usuário",
      "edit-user-button-text": "Editar usuário",
      "span-text-me": "EU",
      "span-text-owner": "PROPRIETÁRIO",
      "span-text-admin": "ADMINISTRADOR",
      "add": "Adicionar",
      "account-users-new_introduction": "Digite o nome e o endereço de email da pessoa que você gostaria de adicionar a esta conta.",
      "first-name": "Primeiro nome...",
      "last-name": "Sobrenome..."
    },
    "bills": {
      "header": "Configurações",
      "contact": "Para visualizar e/ou imprimir recibos de cobrança do RightSignature, clique no botão de download apropriado abaixo. <a href='https://support.sharefile.com/s/'>Ligue para nós</a> para obter assistência ou <a href='https://support.sharefile.com/s/'>entre em contato conosco</a> se você tiver mais dúvidas.",
      "date": "Data",
      "type": "Tipo",
      "status": "Status"
    },
    "api": {
      "header": "Configurações",
      "description": "Estas são as configurações deste usuário e conta.",
      "primary-action": "Solicitar nova chave"
    }
  };
  _exports.account = account;
});