define("ember-rs/utils/feature-flags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ENABLE_UPLOAD_SIGNATURE = _exports.ENABLE_SALESFORCE_DATA_SOURCE = _exports.ENABLE_WORKFLOWS = void 0;
  var ENABLE_WORKFLOWS = 'LCW555-enableWorkflows';
  _exports.ENABLE_WORKFLOWS = ENABLE_WORKFLOWS;
  var ENABLE_SALESFORCE_DATA_SOURCE = 'enable-salesforce-data-source';
  _exports.ENABLE_SALESFORCE_DATA_SOURCE = ENABLE_SALESFORCE_DATA_SOURCE;
  var ENABLE_UPLOAD_SIGNATURE = "enable-upload-signature";
  _exports.ENABLE_UPLOAD_SIGNATURE = ENABLE_UPLOAD_SIGNATURE;
});