define("ember-rs/locales/en/templates/start-document/-send-for-signature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendForSignature = void 0;
  var sendForSignature = {
    "support": "Please <a href='https://support.sharefile.com/s/'>contact Support</a> for assistance."
  };
  _exports.sendForSignature = sendForSignature;
});