define("ember-rs/locales/zh-cn/templates/support-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportContent = void 0;
  var supportContent = {
    "needHelp": "是否需要有关 RightSignature&trade; 的一些帮助?",
    "articlesBelow": "下面是解答您可能针对 RightSignature&trade; 提出的诸多疑问的支持文章和视频。如果这些文章和视频无法解答您的疑问，请随时联系我们的支持代表。",
    "supportArticles": "支持文章",
    "overview": "适用于 ShareFile 的 RightSignature&trade; 概览",
    "activate": "如何在我的 ShareFile 帐户中激活适用于 ShareFile 的 RightSignature&trade; 功能?",
    "send": "如何使用适用于 SF 的 RS 从 ShareFile 发送文档?",
    "sign": "如何使用适用于 SF 的 RS 从 ShareFile 对文档签名?",
    "branding": "如何设置我的公司的外观方案?",
    "changeEmail": "如何更改与我的 RightSignature&trade; 帐户关联的电子邮件地址?",
    "addUser": "如何将用户添加到我的 RightSignature&trade; 帐户?",
    "signerOrder": "如何为同一文档的多个签名者分配顺序?",
    "annotate": "如何使用 RightSignature&trade; 代替我的签名者对文档进行批注?",
    "mergeFields": "如何在发送文档之前使用合并字段在文档中输入文本?",
    "passcode": "如何在我的文档中添加通行码?",
    "importOverlay": "如何使用“导入叠加”功能?",
    "docLocation": "使用适用于 ShareFile 的 RightSignature&trade; 存储的文档位于何处?",
    "share": "如何共享文档?",
    "multiple": "如何发送多个文档以获取签名?",
    "oneOff": "如何发送文档? (相当于一次性)",
    "template": "如何创建可重复使用的模板?",
    "editTemplate": "如何编辑模板?",
    "sendTemplate": "如何发送模板化文档?",
    "embed": "如何在我的 Web 站点中嵌入文档以获取签名?",
    "generate": "如何为我的模板化文档生成共享链接?",
    "delete": "如何删除文档?",
    "tag": "如何标记文档?",
    "selfSign": "如何对我自己的文档进行签名?",
    "reports": "RightSignature&trade; 可以提供哪些报告和统计信息?",
    "complete": "如何填写已发送给您的文档",
    "remind": "如何发送对文档进行签名的提醒?",
    "inPerson": "如何获取亲自签名的文档?",
    "showLess": "显示更少",
    "showMore": "显示更多",
    "sendQuestion": "Need Help? Visit our Help Center",
    "submitRequest": "Connect with our Support Team",
    "call": "立即致电我们",
    "free": "免费电话"
  };
  _exports.supportContent = supportContent;
});