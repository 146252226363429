define("ember-rs/locales/zh-cn/templates/unauthorized/rs3-login-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rs3LoginRedirect = void 0;
  var rs3LoginRedirect = {
    "looking": "正在寻找 RightSignature&trade; - ShareFile 版吗?",
    "sharefileEdition": "由于您尝试登录较旧版本的 RightSignature&trade;，因此您进入了此页面。我们的记录表明您有 RightSignature&trade; - ShareFile 版帐户，请在下面登录。",
    "support": "有问题吗? 请<a href='https://support.sharefile.com/s/'>联系技术支持</a>或致电 1-800-921-4250"
  };
  _exports.rs3LoginRedirect = rs3LoginRedirect;
});