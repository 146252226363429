define("ember-rs/locales/nl/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notFound = void 0;
  var notFound = {
    "doesNotExist": "Deze pagina bestaat niet.",
    "didYou": "Hebt u een koppeling vanaf een andere locatie in RightSignature&trade; gevolgd?",
    "anotherPart": "Als u op deze pagina bent gekomen vanuit een ander deel van RightSignature&trade;, neemt u contact op met <a href='https://support.sharefile.com/s/'>Support</a>, zodat we deze fout kunnen corrigeren.",
    "followLink": "Hebt u een koppeling vanaf een andere site gevolgd?",
    "otherSite": "Koppelingen van andere sites zijn soms verouderd of verkeerd gespeld. Neem <a href='https://support.sharefile.com/s/'>contact op met Support</a> en laat ons weten op welke koppeling u zojuist hebt geklikt, zodat we contact kunnen opnemen met de andere site om het probleem op te lossen.",
    "typeUrl": "Hebt u de URL getypt?",
    "doubleCheck": "Mogelijk hebt u het adres (URL) verkeerd getypt. Controleer of u exact de juiste spelling, hoofdletters, enzovoort hebt getypt.",
    "home": "Start"
  };
  _exports.notFound = notFound;
});