define("ember-rs/locales/ja/templates/onboarding/-agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "title": "サービス利用契約",
    "body": "RightSignature&trade; を使用すると、シトリックスの {{partial 'terms-of-use-link'}} および <a href='{{progressHomePage}}/legal/privacy-center' target='_blank'>プライバシー ポリシー</a>を読み、同意したことを証明することになります。",
    "noThanks": "同意しません",
    "iAgree": "同意します"
  };
  _exports.agreeToTerms = agreeToTerms;
});