define("ember-rs/locales/de/templates/components/trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.trialModal = void 0;
  var trialModal = {
    "ohNo": "Oje!",
    "reminder": "Nur zur Erinnerung:",
    "trialExpired": "Ihre kostenlose Testversion ist abgelaufen.",
    "trialEnds_singular": "Ihre kostenlose Testversion endet in {{trialDays}} Tag.",
    "trialEnds_plural": "Ihre kostenlose Testversion endet in {{trialDays}} Tagen.",
    "day": "Tag",
    "upgradeMessage": "Unterschriften sind immer noch erforderlich? Mit RightSignature-Abonnements sparen Sie Zeit und Geld.",
    "accountSetup": "Einfache Einrichtung, kein Vertrag und jederzeit kündbar.",
    "trialSetup": "Behalten Sie die Dokumente und die Kontoeinstellungen Ihrer Testversion bei.",
    "paperWork": "Sparen Sie jede Woche eine Stunde oder mehr an Verwaltungsaufwand!",
    "moreTime": "Benötigen Sie mehr Zeit für die Bewertung?",
    "call": "<a href='https://support.sharefile.com/s/'>Rufen Sie uns an</a>",
    "logout": "Abmelden",
    "notSure": "Noch nicht sicher? Sehen Sie, was RightSignature für Ihr Unternehmen tun kann."
  };
  _exports.trialModal = trialModal;
});