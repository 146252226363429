define("ember-rs/locales/pt-br/templates/components/archived-migrated-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.archivedMigratedModal = void 0;
  var archivedMigratedModal = {
    "expiredBilling": "Cobrança expirada!",
    "updateBillingDetails": "Seus dados de pagamento não estão atualizados. Clique abaixo para atualizar os dados de cobrança para continuar usando nosso serviço.",
    "clickUpdate": "Atualizar",
    "contactSupport": "<a href='https://support.sharefile.com/s/' class='rs-button is-link is-secondary'>Suporte de contato</a>",
    "logout": "Logout"
  };
  _exports.archivedMigratedModal = archivedMigratedModal;
});