define("ember-rs/locales/fr/templates/unauthorized/rs3-login-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rs3LoginRedirect = void 0;
  var rs3LoginRedirect = {
    "looking": "Vous recherchez RightSignature&trade; - ShareFile Edition ?",
    "sharefileEdition": "Vous avez atteint cette page parce que vous avez tenté de vous connecter à une version antérieure de TradeSignature&. Nos dossiers indiquent que vous disposez d'un compte RightSignature&trade; - ShareFile Edition. Connectez-vous ci-dessous.",
    "support": "Vous avez des questions ? <a href='https://support.sharefile.com/s/'>Contactez le support</a> ou appelez le 1-800-921-4250"
  };
  _exports.rs3LoginRedirect = rs3LoginRedirect;
});