define("ember-rs/locales/pt-br/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notFound = void 0;
  var notFound = {
    "doesNotExist": "Esta página não existe.",
    "didYou": "Você seguiu um link de outro lugar no RightSignature&trade;?",
    "anotherPart": "Se você chegou a esta página vindo de outra parte do RightSignature&trade;, <a href='https://support.sharefile.com/s/'>entre em contato com o Suporte</a> para que possamos corrigir nosso erro.",
    "followLink": "Você seguiu um link de outro site?",
    "otherSite": "Os links de outros sites podem, às vezes, estar desatualizados ou conter erros de grafia. <a href='https://support.sharefile.com/s/'>Entre em contato com o Suporte</a> e diga-nos qual link você clicou para chegar aqui para que possamos tentar entrar em contato com o outro site para corrigir o problema.",
    "typeUrl": "Você digitou o URL?",
    "doubleCheck": "Você pode ter digitado o endereço (URL) incorretamente. Verifique se a grafia e capitalização estão corretas, etc.",
    "home": "Início"
  };
  _exports.notFound = notFound;
});