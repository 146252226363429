define("ember-rs/locales/zh-cn/templates/components/archived-migrated-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.archivedMigratedModal = void 0;
  var archivedMigratedModal = {
    "expiredBilling": "账单已过期!",
    "updateBillingDetails": "您的付款明细不是最新的。请单击下方更新账单明细，以继续使用我们的服务。",
    "clickUpdate": "更新",
    "contactSupport": "请<a href='https://support.sharefile.com/s/' class='rs-button is-link is-secondary'>联系支持人员</a>",
    "logout": "注销"
  };
  _exports.archivedMigratedModal = archivedMigratedModal;
});