define("ember-rs/locales/pt-br/templates/onboarding/-agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "title": "Termos de serviço",
    "body": "Com o uso do RightSignature&trade; você confirma que leu e que concorda com as nossas {{partial 'terms-of-use-link'}} e <a href='{{progressHomePage}}/legal/privacy-center' target='_blank'>Política de Privacidade</a>.",
    "noThanks": "Não, obrigado",
    "iAgree": "Concordo"
  };
  _exports.agreeToTerms = agreeToTerms;
});