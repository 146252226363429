define("ember-rs/models/user", ["exports", "@ember-data/model", "ember-rs/mixins/sharefile-user"], function (_exports, _model, _sharefileUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_sharefileUser.default, {
    ////////////////////////  ATTRIBUTES  ////////////////////////
    name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    avatarUrl: (0, _model.attr)('string'),
    accountId: (0, _model.attr)(),
    sharefileUserId: (0, _model.attr)('string'),
    sharefileRoles: (0, _model.attr)('array'),
    fullNameShort: (0, _model.attr)('string'),
    password: (0, _model.attr)('string'),
    isConfirmed: (0, _model.attr)('boolean'),
    integrationProviderMetadata: (0, _model.attr)('object'),
    sharefileAccountUrl: (0, _model.attr)('string'),
    timezone: (0, _model.attr)('string'),
    dateformat: (0, _model.attr)('string'),
    timeformat: (0, _model.attr)('string'),
    dateFormatFlatpickr: (0, _model.attr)('string'),
    // Privileges
    free: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    entitled: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    // Relationships
    account: (0, _model.belongsTo)('account', {
      async: true
    }),
    signatures: (0, _model.hasMany)('signatures', {
      async: false
    }),
    integrations: (0, _model.hasMany)('integrations', {
      async: false
    }),
    emailVerifications: (0, _model.hasMany)('email-verification', {
      async: false
    }),
    // Settings
    contactSearching: (0, _model.attr)('boolean'),
    documentViewedNotification: (0, _model.attr)('boolean'),
    documentSignedNotification: (0, _model.attr)('boolean'),
    documentCompletedNotification: (0, _model.attr)('boolean'),
    hasSeenRectDetect: (0, _model.attr)('boolean'),
    hasSeenRestyleOnboarding: (0, _model.attr)('boolean'),
    hasSeenWelcomeOnboarding: (0, _model.attr)('boolean'),
    hasSeenPrepareOnboarding: (0, _model.attr)('boolean'),
    hasSeenSendOnboarding: (0, _model.attr)('boolean'),
    hasSeenSignerOnboarding: (0, _model.attr)('boolean'),
    hasSeenAccountOnboarding: (0, _model.attr)('boolean'),
    hasSeenMigrationOnboarding: (0, _model.attr)('boolean'),
    migrationOnboardingPageNo: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    openMigrationPopup: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    hasAgreedToTerms: (0, _model.attr)('boolean'),
    useRectDetect: (0, _model.attr)('boolean'),
    senderAsSigner: (0, _model.attr)('boolean'),
    documentExpiration: (0, _model.attr)('number'),
    reminderFrequency: (0, _model.attr)('string'),
    attachPdfOnCompletion: (0, _model.attr)('boolean'),
    emailGreeting: (0, _model.attr)('string'),
    emailSignature: (0, _model.attr)('string'),
    showEmailSenderNameInFrom: (0, _model.attr)('boolean'),
    showEmailSubjectCompanyName: (0, _model.attr)('boolean'),
    showEmailSubjectDocumentFilename: (0, _model.attr)('boolean'),
    allowTypedSignature: (0, _model.attr)('boolean'),
    allowDrawnSignature: (0, _model.attr)('boolean'),
    allowWebcamSignature: (0, _model.attr)('boolean'),
    allowUploadedSignature: (0, _model.attr)('boolean'),
    ////////////////////////  PROPERTIES  ////////////////////////
    allIntegrationProviders: Ember.computed('integrationProviderMetadata', function () {
      return Object.keys(this.integrationProviderMetadata);
    }),
    emailOrPending: Ember.computed('email', function () {
      return this.email || 'Pending email verification...';
    }),
    initials: Ember.computed('fullNameShort', function () {
      var names = this.fullNameShort.split(' ');
      return [names[0].charAt(0), names[1].charAt(0)].join('').toUpperCase();
    }),
    timestamp: Ember.computed('dateformat', 'timeformat', function () {
      return this.dateformat + " " + this.timeformat;
    }),
    loadSignatures: function loadSignatures() {
      return this.store.query('signature', {});
    }
  });

  _exports.default = _default;
});